const loadKeycloakScript = () => {
  return new Promise(resolve => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_KEYCLOAK_SCRIPT_URL;
    script.id = "keycloak";
    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    };
  });
};

export default class BootstrapService {
  static before = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      await loadKeycloakScript();
      resolve();
    });
  };
}
