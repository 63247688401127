import axios from "axios";

export default class InspectionsService {
  static getInspections() {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/available-inspections`, {
        params: {
          join: "availableAction",
          sort: "available-inspection.significance:ASC",
        },
      })
      .then(response => {
        return response.data;
      });
  }

  static getActions() {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/available-actions`)
      .then(response => {
        return response.data;
      });
  }

  static updateInspection({ inspectionId, data }) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH_URL}/available-inspections/${inspectionId}`,
        data,
      )
      .then(response => {
        return response.data;
      });
  }

  static deleteInspection(inspectionId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_PATH_URL}/available-inspections/${inspectionId}`,
      )
      .then(response => {
        return response.data;
      });
  }

  static addInspection(data) {
    return axios
      .post(`${process.env.REACT_APP_API_PATH_URL}/available-inspections`, data)
      .then(response => {
        return response.data;
      });
  }
}
