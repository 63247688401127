export const ECARTEE = "Ecartée ou non";

export const FRAUDE = "Fraude";

export const DATE = "Date trt";

export const REFERENCE = "Numero de dossier";

export const STATUS = "Etat du dossier";

export const MOTIF = "motif de l'écart";

export const AMOUNT = "Mt remise";
