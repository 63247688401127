const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "decembre",
];

export const getDate = date => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `Le ${date.getDate()} ${date.getMonth() &&
    monthNames[date.getMonth()]} ${hours < 10 ? `0${hours}` : hours}h${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const getFileDate = date => {
  const fileDate = date ? new Date(date).toLocaleDateString() : null;
  return fileDate;
};
