import React, { useRef } from "react";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import Block from "@material-ui/icons/BlockOutlined";
import Description from "@material-ui/icons/Description";
import { OK, KO, DEFAULT, INSPECTION, GLOBAL } from "../../utils/inspections";
import { download } from "../../utils/DownloadUtil";
import Loader from "../Loader";
import { getDate } from "../../utils/date";
import IcoPending from "../../icons/IcoPending";

const useStyles = makeStyles(theme => ({
  commentsList: {
    padding: 0,
  },
  listItem: {
    display: "block",
    padding: theme.spacing(2, 0),
    border: "none",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  commentLabelGrid: {
    marginRight: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
  },
  commentContentGrid: {
    padding: theme.spacing(1, 0),
  },
  commentLabel: {
    color: theme.palette.text.primary,
  },
  authorGrid: {
    marginTop: "auto",
    marginBottom: "auto",
    padding: theme.spacing(0.5, 0),
  },
  author: {
    fontWeight: "normal",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "1.4rem",
  },
  iconGrid: {
    display: "flex",
  },
  okIcon: {
    color: theme.palette.background.ok,
  },
  koIcon: {
    color: theme.palette.background.secondary,
  },
  neutralIcon: {
    fill: theme.palette.background.in_process,
  },
  fileAttach: {
    fontSize: "2rem",
  },
  infiniteScroll: {
    height: theme.spacing(70),
    overflow: "auto",
  },
  fileAttachButtonGrid: {
    margin: "auto",
  },
  fileAttachButton: {
    padding: 0,
  },
  commentDateTypography: {
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    fontWeight: "normal",
  },
  commentContent: {
    color: theme.palette.secondary.main,
    fontSize: "1rem",
  },
  itemInfoGrid: {
    alignItems: "center",
  },
}));

const CommentsList = props => {
  const { loadMore, listState } = props;

  const classes = useStyles();
  const scrollRef = useRef();

  const handleAttachment = (commentID, attachmentID) => {
    download({ commentID, attachmentID });
  };

  return (
    <div className={classes.infiniteScroll} ref={scrollRef}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={listState.hasMoreComments}
        loader={<Loader key={0} />}
        useWindow={false}
        pageStart={0}
        getScrollParent={() => scrollRef && scrollRef.current}
      >
        <List className={classes.commentsList}>
          {listState.comments &&
            Array.isArray(listState.comments) &&
            listState.comments.map(cm => {
              const date = cm.created ? new Date(cm.created) : null;
              return (
                <ListItem className={classes.listItem} key={cm.id}>
                  <Grid container className={classes.itemInfoGrid}>
                    <Grid item xs="auto" className={classes.iconGrid}>
                      {cm.inspectionStatus === DEFAULT && (
                        <IcoPending
                          className={classNames(
                            classes.icon,
                            classes.neutralIcon,
                          )}
                        />
                      )}
                      {cm.inspectionStatus === OK && (
                        <CheckCircle
                          className={classNames(classes.icon, classes.okIcon)}
                        />
                      )}
                      {cm.inspectionStatus === KO && (
                        <Block
                          className={classNames(classes.icon, classes.koIcon)}
                        />
                      )}
                    </Grid>
                    <Grid item xs="auto" className={classes.commentLabelGrid}>
                      <Typography variant="h3" className={classes.commentLabel}>
                        {cm.target === INSPECTION &&
                          (cm.inspectionStatus === DEFAULT
                            ? `Contrôle à faire: ${cm.inspectionLabel}`
                            : `Contrôle ${cm.inspectionStatus}: ${cm.inspectionLabel}`)}
                        {cm.target === GLOBAL && "Commentaire global"}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto" className={classes.authorGrid}>
                      <Typography className={classes.author}>
                        Réalisé par {cm.author}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={11} className={classes.commentContentGrid}>
                      <Typography className={classes.commentContent}>
                        {cm.content}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.fileAttachButtonGrid}>
                      {cm.attachmentId && (
                        <IconButton
                          onClick={() =>
                            handleAttachment(cm.id, cm.attachmentId)
                          }
                          className={classes.fileAttachButton}
                        >
                          <Description
                            color="primary"
                            className={classes.fileAttach}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  <Typography className={classes.commentDateTypography}>
                    {getDate(date)}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
      </InfiniteScroll>
    </div>
  );
};

CommentsList.propTypes = {
  loadMore: PropTypes.func.isRequired,
  listState: PropTypes.shape().isRequired,
};

export default CommentsList;
