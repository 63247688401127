import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles(theme => ({
  imgModal: {
    height: theme.spacing(30),
  },
  photos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  navigation: {
    fontSize: "5rem",
  },
  navigationButonLeft: {
    position: "absolute",
    left: theme.spacing(5),
  },
  navigationButonRight: {
    position: "absolute",
    right: theme.spacing(5),
  },
  notFoundImage: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  photoButton: {
    borderRadius: 0,
  },
}));

const ChequesInfos = props => {
  const {
    chequesNb,
    slipPhotoURL,
    photoURL,
    photosCount,
    rectoPhoto,
    handleSwitch,
    handleChangeRectoVerso,
  } = props;

  const classes = useStyles();

  const [notFoundImage, setNotFoundImage] = useState(false);
  const [notFoundSlipImage, setNotFoundSlipImage] = useState(false);

  const handleNotFoundImage = () => {
    setNotFoundImage(true);
  };

  const handleNotFoundSlipImage = () => {
    setNotFoundSlipImage(true);
  };

  useEffect(() => {
    setNotFoundSlipImage(false);
  }, [slipPhotoURL]);

  useEffect(() => {
    setNotFoundImage(false);
  }, [photoURL]);

  return (
    <>
      <div className={classes.photos}>
        {!notFoundSlipImage && (
          <img
            className={classes.imgModal}
            alt="Bordereau de remise de chèque"
            src={slipPhotoURL}
            onError={handleNotFoundSlipImage}
          />
        )}
        {notFoundSlipImage && (
          <Typography variant="h2" className={classes.notFoundImage}>
            IMAGE NON TROUVÉE
          </Typography>
        )}
      </div>
      <div className={classes.photos}>
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => handleSwitch("left")}
          disabled={photosCount === 0}
          className={classes.navigationButonLeft}
        >
          <ChevronLeft
            className={classes.navigation}
            color={photosCount === 0 ? "disabled" : "primary"}
          />
        </IconButton>
        <Tooltip
          title={
            rectoPhoto
              ? "Cliquez pour afficher le verso du chèque"
              : "Cliquez pour afficher le recto du chèque"
          }
        >
          <Button
            disableRipple
            disableFocusRipple
            onClick={handleChangeRectoVerso}
            className={classes.photoButton}
          >
            {!notFoundImage && (
              <img
                className={classes.imgModal}
                alt={`Chèque ${photosCount + 1}`}
                src={photoURL}
                onError={handleNotFoundImage}
              />
            )}
            {notFoundImage && (
              <Typography variant="h2" className={classes.notFoundImage}>
                IMAGE NON TROUVÉE
              </Typography>
            )}
          </Button>
        </Tooltip>
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => handleSwitch("right")}
          disabled={photosCount === chequesNb - 1}
          className={classes.navigationButonRight}
        >
          <ChevronRight
            className={classes.navigation}
            color={photosCount === chequesNb - 1 ? "disabled" : "primary"}
          />
        </IconButton>
      </div>
    </>
  );
};

ChequesInfos.propTypes = {
  chequesNb: PropTypes.number.isRequired,
  slipPhotoURL: PropTypes.string.isRequired,
  photoURL: PropTypes.string.isRequired,
  photosCount: PropTypes.number.isRequired,
  rectoPhoto: PropTypes.bool,
  handleSwitch: PropTypes.func.isRequired,
  handleChangeRectoVerso: PropTypes.func.isRequired,
};

ChequesInfos.defaultProps = {
  rectoPhoto: true,
};

export default ChequesInfos;
