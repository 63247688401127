import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import propTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AdaptiveModal from "../Modal/AdaptiveModal";

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    lineHeight: "1.25rem",
  },
  buttonGrid: {
    textAlign: "right",
  },
  button: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
}));

const AlertDialog = props => {
  const {
    contentText,
    disagree,
    agree,
    closeDialog,
    quitWithoutSaving,
    openClose,
    handleClose,
    title,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <AdaptiveModal
        openModal={openClose}
        handleClose={handleClose}
        title={title}
        width={78}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography className={classes.content}>{contentText}</Typography>
        <div className={classes.buttonGrid}>
          <Button
            onClick={closeDialog}
            color="primary"
            className={classes.button}
          >
            {disagree}
          </Button>
          <Button
            onClick={quitWithoutSaving}
            color="primary"
            className={classes.button}
            autoFocus
          >
            {agree}
          </Button>
        </div>
      </AdaptiveModal>
    </div>
  );
};

AlertDialog.propTypes = {
  contentText: propTypes.string.isRequired,
  disagree: propTypes.string.isRequired,
  agree: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  closeDialog: propTypes.func.isRequired,
  quitWithoutSaving: propTypes.func.isRequired,
  openClose: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
};

export default AlertDialog;
