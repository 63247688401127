import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  modalPaper: width => ({
    position: "absolute",
    maxWidth: "95%",
    width: theme.spacing(width),
    background: theme.palette.background.paper,
    borderRadius: "10px",
    padding: theme.spacing(2.5),
  }),
  divider: {
    margin: 0,
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  closeIconButton: {
    padding: 0,
  },
  closeIcon: {
    fontSize: "2rem",
  },
}));

const AdaptiveModal = props => {
  const { openModal, handleClose, title, children, width, withDivider } = props;

  const classes = useStyles(width);

  const getModalStyle = () => {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  };

  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={classes.modalTitle}>
          <Typography variant="h2" className={classes.modalTitleTypography}>
            {title}
          </Typography>
          <IconButton
            className={classes.closeIconButton}
            color="primary"
            disableRipple
            disableFocusRipple
            onClick={handleClose}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
        {withDivider && <Divider className={classes.divider} />}
        {children}
      </div>
    </Modal>
  );
};

AdaptiveModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  withDivider: PropTypes.bool,
};

AdaptiveModal.defaultProps = {
  title: "",
  width: 170,
  withDivider: true,
};
export default AdaptiveModal;
