import React, { useState, useEffect, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { PUBLIC_ROUTING, PRIVATE_ROUTING } from "./routes";
import Layout from "../layouts/Layout";
import HomeNotConnected from "./HomeNotConnected";
import Home from "./Home";
import Dashboard from "./Dashboard";
import File from "./File";
import Inspections from "./Inspections";
import P404 from "./P404";
import AuthService from "../services/AuthService";

const Routes = () => {
  const [loading, setLoading] = useState(true);
  const [userCategories, setUserCategories] = useState();

  useEffect(() => {
    const getUserProfile = async () => {
      await AuthService.getProfile().then(profile => {
        setUserCategories(
          profile && profile.attributes && profile.attributes.category,
        );
      });
    };
    const checkAuth = async () => {
      const logged = await AuthService.checkAccess();
      if (logged) {
        getUserProfile();
        setLoading(false);
      } else {
        console.error("Erreur lors de la connexion");
      }
    };
    checkAuth();
  }, []);

  const getRouteComponent = route => {
    switch (route.key) {
      case "home-not-connected":
        return HomeNotConnected;
      case "home":
        return Home;
      case "dashboard":
        return Dashboard;
      case "inspections":
        return Inspections;
      case "file":
        return File;
      default:
        break;
    }
    return null;
  };

  const renderRoute = route => {
    const component = getRouteComponent(route);
    if (!component) {
      return null;
    }
    if (loading) {
      return <Fragment key="loading"> </Fragment>;
    }
    if (component === Home || component === Dashboard) {
      if (!userCategories) {
        return <Fragment key="loading"> </Fragment>;
      }
      if (component === Home) {
        return (
          <Route
            exact
            render={props => (
              <Home {...props} userCategories={userCategories} />
            )}
            {...route}
          />
        );
      }
      return (
        <Route
          exact
          render={props => (
            <Dashboard {...props} userCategories={userCategories} />
          )}
          {...route}
        />
      );
    }
    return <Route exact component={component} {...route} />;
  };

  const Wrapper = loading ? Fragment : Layout;

  return (
    <Router history={history}>
      <Wrapper>
        <Switch>
          {PUBLIC_ROUTING.map(renderRoute)}

          {PRIVATE_ROUTING.map(renderRoute)}
          <Route component={P404} />
        </Switch>
      </Wrapper>
    </Router>
  );
};

export default Routes;
