import axios from "axios";
import { CHEQUE } from "../utils/cheque";

export default class FilesService {
  static getFiles({ filter, sort, page, limit }) {
    const filterString =
      filter &&
      (Array.isArray(filter)
        ? filter.map(ft => `filter=${ft}`).join("&")
        : `filter=${filter}`);
    const query = filterString ? `&${filterString}` : "";
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files?join=deposit&join=children${query}`,
        {
          params: {
            sort,
            page,
            limit,
          },
        },
      )
      .then(response => {
        return response.data;
      });
  }

  static getStatsFiles({ filter, sort, page, limit, csv }) {
    const filterString =
      filter &&
      (Array.isArray(filter)
        ? filter.map(ft => `filter=${ft}`).join("&")
        : `filter=${filter}`);
    const query = filterString ? `?${filterString}` : "";
    let params;
    if (csv) {
      params = {
        sort,
        csv,
      };
    } else {
      params = {
        sort,
        page,
        limit,
      };
    }
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/statistics${query}`, {
        params,
      })
      .then(response => {
        return response;
      });
  }

  static getHistoryFiles({ filter, sort, page, limit }) {
    const filterString =
      filter &&
      (Array.isArray(filter)
        ? filter.map(ft => `filter=${ft}`).join("&")
        : `filter=${filter}`);
    const query = filterString ? `&${filterString}` : "";
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files?join=deposit&join=auditActions&join=children${query}`,
        {
          params: {
            sort,
            page,
            limit,
          },
        },
      )
      .then(response => {
        return response.data;
      });
  }

  static getLinkedFiles({ fileId, filter, page, limit }) {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/links`, {
        params: {
          filter,
          page,
          limit,
        },
      })
      .then(response => {
        return response.data;
      });
  }

  static getFilesNbByStatusAndCategory({ status, category }) {
    const statusString =
      status &&
      Array.isArray(status) &&
      status.map(st => `status=${st}`).join("&");
    const categoryString =
      category &&
      Array.isArray(category) &&
      category.map(cat => `category=${cat}`).join("&");
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files-count?${statusString}&${categoryString}&extra=histo`,
      )
      .then(response => {
        return response.data;
      });
  }

  static getFile(id) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files/${id}?join=deposit&join=todoInspections&join=supplementaryDetails&join=children&join=children.deposit`,
      )
      .then(response => {
        return response.data;
      });
  }

  static getCheques(id) {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/deposits/${id}/cheques`, {
        params: {
          limit: 5,
        },
      })
      .then(response => {
        return response.data;
      });
  }

  static updateInspection({ fileId, inspectionId, data }) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/todo-inspections/${inspectionId}`,
        data,
      )
      .then(response => {
        return response.data;
      });
  }

  static getComments({ fileId, page, commentsByPage }) {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/comments/`, {
        params: {
          page: page || 1,
          limit: commentsByPage || 5,
          sort: "created:DESC",
        },
      })
      .then(response => {
        return response.data;
      });
  }

  static getCommentsNb(fileId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/comments-count`,
      )
      .then(response => {
        return response.data;
      });
  }

  static updateFile({ fileId, data }) {
    return axios
      .patch(`${process.env.REACT_APP_API_PATH_URL}/files/${fileId}`, data)
      .then(response => {
        return response.data;
      });
  }

  static getPicture({ fileId, type, reference, side }) {
    const params = {
      type,
    };
    if (type === CHEQUE) {
      params.side = side;
      params.reference = reference;
    }
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/pictures`, {
        params,
        responseType: "blob",
      })
      .then(response => {
        return response;
      });
  }

  static updateChildFile({ fileId, childFileId, provenFraud }) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/children/${childFileId}`,
        { provenFraud },
      )
      .then(response => {
        return response.data;
      });
  }
}
