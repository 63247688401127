import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Lock from "@material-ui/icons/LockOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import Block from "@material-ui/icons/BlockOutlined";
import Comment from "@material-ui/icons/CommentOutlined";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IcoPending from "../../icons/IcoPending";
import { OK, KO, DEFAULT } from "../../utils/inspections";

const useStyles = makeStyles(theme => ({
  paperInspections: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  inspectionsTable: {
    marginTop: theme.spacing(2.5),
    overflow: "auto",
    display: "flex",
  },
  iconButton: {
    padding: 0,
    marginLeft: theme.spacing(0.45),
    marginRight: theme.spacing(0.45),
  },
  okIcon: {
    color: theme.palette.background.ok,
  },
  koIcon: {
    color: theme.palette.background.secondary,
  },
  neutralIcon: {
    fill: theme.palette.background.in_process,
  },
  disabledIcon: {
    color: theme.palette.background.disabled,
    fill: theme.palette.background.disabled,
  },
  commentIcon: {
    color: theme.palette.background.neutral,
  },
  lockIcon: {
    marginTop: theme.spacing(1),
  },
  lockCell: {
    padding: theme.spacing(0, 1, 0, 2),
    color: theme.palette.background.secondary,
    width: "2rem",
    border: "none",
  },
  inspectionsTypography: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  fullField: {
    width: theme.spacing(74),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(44),
    },
    alignItems: "center",
  },
  inputField: {
    width: "100%",
    marginLeft: theme.spacing(1),
  },
  commentsNb: {
    paddingLeft: theme.spacing(1),
  },
  commentButton: {
    padding: 0,
    marginRight: theme.spacing(3.5),
    marginLeft: theme.spacing(2),
  },
  inspectionsRow: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  inspectionsCell: {
    padding: theme.spacing(1.5, 0.25),
    border: "none",
  },
  inspectionsIconsCell: {
    minWidth: "6.5rem",
    width: "6.5rem",
  },
  inputFieldText: {
    height: "2rem",
    fontSize: "0.875rem",
    backgroundColor: theme.palette.background.paper,
  },
  inspectionsTypographyGrid: {
    margin: "auto",
  },
}));

const InspectionsTable = props => {
  const {
    inspections,
    commentsNb,
    handleStateChange,
    handleChangeFieldValue,
    handleInspectionCommentsClick,
  } = props;

  const classes = useStyles();

  const commentsNbByInspection = inspectionId => {
    const comment = commentsNb.find(cm => cm.inspectionId === inspectionId);
    return (comment && comment.commentNumber) || 0;
  };

  const sortedInspections =
    inspections &&
    Array.isArray(inspections) &&
    inspections.sort((a, b) => {
      return a.significance - b.significance;
    });

  const blockingInspections =
    sortedInspections &&
    Array.isArray(sortedInspections) &&
    sortedInspections.filter(ins => ins.blocking === true);

  const notBlockingInspections =
    sortedInspections &&
    Array.isArray(sortedInspections) &&
    sortedInspections.filter(ins => ins.blocking === false);

  const getTableBody = ins => {
    return (
      <TableRow key={ins.id} className={classes.inspectionsRow}>
        <TableCell className={classes.lockCell}>
          {ins.blocking && (
            <Lock className={classes.lockIcon} color="secondary" />
          )}
        </TableCell>
        <TableCell
          className={classNames(
            classes.inspectionsCell,
            classes.inspectionsIconsCell,
          )}
        >
          <Tooltip title="contrôle à faire">
            <span>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleStateChange(ins.id, DEFAULT)}
                disableRipple
                disabled={!handleStateChange}
              >
                <IcoPending
                  className={
                    ins.status === DEFAULT
                      ? classes.neutralIcon
                      : classes.disabledIcon
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="contrôle KO">
            <span>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleStateChange(ins.id, KO)}
                disableRipple
                disabled={!handleStateChange}
              >
                <Block
                  className={
                    ins.status === KO ? classes.koIcon : classes.disabledIcon
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="contrôle OK">
            <span>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleStateChange(ins.id, OK)}
                disableRipple
                disabled={!handleStateChange}
              >
                <CheckCircle
                  className={
                    ins.status === OK ? classes.okIcon : classes.disabledIcon
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.inspectionsCell}>
          <IconButton
            onClick={() => handleInspectionCommentsClick(ins.id)}
            className={classes.commentButton}
            disableRipple
            disabled={!handleInspectionCommentsClick}
          >
            <Comment className={classes.commentIcon} />
            <Typography className={classes.commentsNb}>
              {commentsNbByInspection(ins.id)}
            </Typography>
          </IconButton>
        </TableCell>
        <TableCell className={classes.inspectionsCell}>
          <Grid container className={classes.fullField}>
            <Grid
              item
              xs={ins.displayInputField ? 9 : 12}
              className={classes.inspectionsTypographyGrid}
            >
              <Tooltip
                title={ins.label}
                disableHoverListener={
                  !(
                    ins.ref &&
                    ins.ref.current &&
                    ins.ref.current.scrollWidth > ins.ref.current.clientWidth
                  )
                }
              >
                <Typography
                  ref={ins.ref}
                  className={classes.inspectionsTypography}
                >
                  {ins.label}
                </Typography>
              </Tooltip>
            </Grid>
            {ins.displayInputField && (
              <Grid item xs={3}>
                <TextField
                  className={classes.inputField}
                  variant="outlined"
                  size="small"
                  value={ins.inputFieldValue || ""}
                  disabled={!handleChangeFieldValue}
                  onChange={
                    handleChangeFieldValue && handleChangeFieldValue(ins.id)
                  }
                  InputProps={{
                    inputProps: { maxLength: "10" },
                    className: classes.inputFieldText,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper className={classes.paperInspections}>
      <Typography variant="h2">Aide à la décision</Typography>
      <Table className={classes.inspectionsTable}>
        <TableBody>
          {blockingInspections &&
            blockingInspections.map(ins => {
              return getTableBody(ins);
            })}
          {notBlockingInspections &&
            notBlockingInspections.map(ins => {
              return getTableBody(ins);
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

InspectionsTable.propTypes = {
  inspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  commentsNb: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleStateChange: PropTypes.func,
  handleChangeFieldValue: PropTypes.func,
  handleInspectionCommentsClick: PropTypes.func,
};

InspectionsTable.defaultProps = {
  handleStateChange: null,
  handleInspectionCommentsClick: null,
  handleChangeFieldValue: null,
};

export default InspectionsTable;
