import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import FilesToggleButton from "./FilesToggleButton";
import { STATUSES, MY_HISTORY } from "../../utils/filters";

const useStyles = makeStyles(theme => ({
  toggleDouble: {
    marginTop: theme.spacing(0.5),
    background: theme.palette.background.default,
    width: theme.spacing(42),
  },
  toggle: {
    marginTop: theme.spacing(0.5),
    background: theme.palette.background.default,
  },
  categoryTypeTypography: {
    color: "#666D92",
  },
  toggleButton: {
    "&:not(:first-child)": {
      borderRadius: "200px",
      marginRight: theme.spacing(2),
    },
    "&:first-child": {
      borderRadius: "200px",
      marginRight: theme.spacing(2),
    },
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
}));

const FilesToggleButtonGroup = props => {
  const {
    title,
    status,
    category,
    handleChangeStatus,
    double,
    filtersFilesNb,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.categoryTypeTypography}>
        {title}
      </Typography>
      <ToggleButtonGroup
        value={status}
        exclusive
        className={double ? classes.toggleDouble : classes.toggle}
        onChange={handleChangeStatus(category)}
      >
        {STATUSES.slice(1, 4).map(st => (
          <ToggleButton
            disableRipple
            className={classes.toggleButton}
            value={st.value}
            key={`${st.label}-${st.value}`}
          >
            <FilesToggleButton
              category={category}
              status={st}
              filesNb={filtersFilesNb[st.value]}
            />
          </ToggleButton>
        ))}
        <ToggleButton
          disableRipple
          className={classes.toggleButton}
          value={MY_HISTORY.value}
        >
          <FilesToggleButton
            category={category}
            status={MY_HISTORY}
            filesNb={filtersFilesNb.HISTO}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

FilesToggleButtonGroup.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  double: PropTypes.bool,
  filtersFilesNb: PropTypes.shape().isRequired,
};

FilesToggleButtonGroup.defaultProps = {
  double: false,
};

export default FilesToggleButtonGroup;
