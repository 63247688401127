import React, { useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import CheckBox from "@material-ui/icons/CheckBoxOutlined";
import AdaptiveModal from "./Modal/AdaptiveModal";
import ActionsService from "../services/ActionsService";
import ActionsList from "./List/ActionsList";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(0.5),
  },
  toDoActionsList: {
    paddingTop: theme.spacing(2),
  },
  actionsLabel: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  actionLabel: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
}));

const Actions = props => {
  const { file, openModalActions, handleCloseActionsModal } = props;

  const classes = useStyles();

  const [toDoActions, setToDoActions] = useState();
  const [listState, setListState] = useState({
    currentPage: 0,
    hasMoreActions: true,
    actions: [],
    load: false,
  });

  const getActions = useCallback(() => {
    ActionsService.getToDoActions(file.id).then(actions => {
      const visibleActions =
        actions &&
        actions.content &&
        Array.isArray(actions.content) &&
        actions.content.filter(action => action.visible);
      setToDoActions(visibleActions);
    });
  }, [file.id]);

  useEffect(() => {
    if (openModalActions) {
      getActions();
      setListState({
        currentPage: 0,
        hasMoreActions: true,
        actions: [],
        load: false,
      });
    }
  }, [openModalActions, getActions]);

  const actionsByPage = 10;

  const loadActions = async () => {
    const { currentPage, actions } = listState;
    const res = await ActionsService.getActions({
      fileId: file.id,
      page: currentPage,
      actionsByPage,
    });
    const content = [...actions, ...res.content];
    setListState({
      currentPage,
      hasMoreActions:
        res.content.length !== 0 && content.length < res.metadatas.total,
      actions: content,
      load: false,
    });
  };

  useEffect(() => {
    if (listState.load) {
      loadActions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState.load]);

  const loadMore = () => {
    const { currentPage, actions } = listState;
    setListState({
      currentPage: currentPage + 1,
      hasMoreActions: false,
      actions,
      load: true,
    });
  };

  const handleClose = () => {
    handleCloseActionsModal();
  };

  const handleChange = async action => {
    await ActionsService.updateToDoAction({
      fileId: file.id,
      todoActionId: action.id,
      done: !action.done,
    });
    getActions();
    setListState({
      currentPage: 1,
      hasMoreActions: false,
      actions: [],
      load: true,
    });
  };

  return (
    <AdaptiveModal
      openModal={openModalActions}
      handleClose={handleClose}
      title="Actions sur dossier"
    >
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h3" className={classes.actionsLabel}>
            Actions à effectuer
          </Typography>
          <List className={classes.toDoActionsList}>
            {toDoActions &&
              Array.isArray(toDoActions) &&
              toDoActions.map(action => (
                <ListItem key={action.id} className={classes.listItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={action.done}
                        onChange={() => handleChange(action)}
                        name="action"
                        color="primary"
                        checkedIcon={<CheckBox />}
                      />
                    }
                    label={
                      <Typography className={classes.actionLabel}>
                        {action.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.actionsList}>
            <ActionsList loadMore={loadMore} listState={listState} />
          </Paper>
        </Grid>
      </Grid>
    </AdaptiveModal>
  );
};

Actions.propTypes = {
  file: PropTypes.shape().isRequired,
  openModalActions: PropTypes.bool.isRequired,
  handleCloseActionsModal: PropTypes.func.isRequired,
};

export default Actions;
