import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Clear from "@material-ui/icons/Clear";
import FilesService from "../services/FilesService";
import FilterFields from "../components/Filters/FilterFields";
import FilesToggleButtonGroup from "../components/Toggle/FilesToggleButtonGroup";
import FilesTable from "../components/Table/FilesTable";
import { STATUSES, CATEGORIES, MY_HISTORY } from "../utils/filters";
import IcoFilter from "../icons/IcoFilter";
import AuthService from "../services/AuthService";
import { A_TRAITER, EN_SUSPENS } from "../utils/status";

const useStyles = makeStyles(theme => ({
  homePage: {
    maxWidth: theme.spacing(190),
    margin: "auto",
  },
  paperFilters: {
    margin: theme.spacing(5, 0),
    background: theme.palette.background.default,
  },
  paperTable: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  icon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  iconButton: {
    padding: 0,
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
    },
  },
  categoryDivider: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(8),
    marginTop: 0,
  },
  searchGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  input: {
    height: "2.5rem",
    width: "21rem",
    marginTop: theme.spacing(5),
  },
  searchIconButton: {
    color: theme.palette.background.paper,
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  searchInput: {
    backgroundColor: theme.palette.background.default,
  },
  searchOutlinedInput: {
    backgroundColor: theme.palette.primary.main,
  },
  searchIcon: {
    fontSize: "1.2rem",
  },
  iconFilterButton: {
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    height: "2.5rem",
  },
  iconFilter: {
    fill: theme.palette.primary.main,
  },
  clearIconButton: {
    borderRadius: "0%",
    backgroundColor: theme.palette.background.default,
    marginLeft: -theme.spacing(1),
    "&:hover": {
      borderRadius: "0%",
      backgroundColor: theme.palette.background.default,
      marginLeft: -theme.spacing(1),
    },
  },
  clearIcon: {
    fontSize: "1.2rem",
  },
  noSearchResult: {
    paddingTop: theme.spacing(10),
    textAlign: "center",
  },
}));

const Home = props => {
  const classes = useStyles();

  const { userCategories } = props;

  const ENTER_KEY = 13;
  const localStorageFilters = localStorage
    ? {
      status: localStorage.getItem("status"),
      category: localStorage.getItem("category"),
      fileNb: localStorage.getItem("fileNb"),
      documentNb: localStorage.getItem("documentNb"),
      account: localStorage.getItem("account"),
      processingDateStart: localStorage.getItem("processingDateStart"),
      processingDateEnd: localStorage.getItem("processingDateEnd"),
      amountMin: localStorage.getItem("amountMin"),
      amountMax: localStorage.getItem("amountMax"),
      history: localStorage.getItem("history"),
      openFilters: localStorage.getItem("openFilters"),
    }
    : null;

  const accessToBothCategories =
    userCategories &&
    Array.isArray(userCategories) &&
    userCategories.length === 2;

  const singleCategoryValue =
    accessToBothCategories === false
      ? userCategories && Array.isArray(userCategories) && userCategories[0]
      : false;

  const [category, setCategory] = useState(
    localStorageFilters?.category ||
    (accessToBothCategories ? CATEGORIES[0].value : singleCategoryValue),
  );
  const [files, setFiles] = useState([]);
  const [filesNb, setFilesNb] = useState(0);
  const [status, setStatus] = useState(
    localStorageFilters?.status || (localStorageFilters && Object.values(localStorageFilters).some(value => value && value !== "") ? STATUSES[0].value : A_TRAITER),
  );
  const [history, setHistory] = useState(localStorageFilters?.history === "true");
  const [openFilters, setOpenFilters] = useState(
    localStorageFilters?.openFilters === "true",
  );
  const [filters, setFilters] = useState({
    documentNb: localStorageFilters?.documentNb || "",
    account: localStorageFilters?.account || "",
    processingDateStart: localStorageFilters?.processingDateStart || "",
    processingDateEnd: localStorageFilters?.processingDateEnd || "",
    amountMin: localStorageFilters?.amountMin || "",
    amountMax: localStorageFilters?.amountMax || "",
  });
  const [currentFilters, setCurrentFilters] = useState(false);
  const [fileNb, setFileNb] = useState(localStorageFilters?.fileNb || "");

  const [noSearchResult, setNoSearchResult] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("file.status");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [proFiltersFilesNb, setProFiltersFilesNb] = useState({});
  const [partFiltersFilesNb, setPartFiltersFilesNb] = useState({});

  const userId = AuthService.getUserId();

  const getAdvancedFilters = () => {
    const advancedFilters = [];
    if (status) {
      advancedFilters.push(
        status === EN_SUSPENS
          ? "file.status||sw||En suspens"
          : `file.status||eq||${status}`,
      );
    }
    if (category) {
      advancedFilters.push(`file.category||eq||${category}`);
    }
    if (filters.documentNb) {
      advancedFilters.push(`deposit.reference||eq||${filters.documentNb}`);
    }
    if (filters.account) {
      advancedFilters.push(`deposit.beneficiaryAccount||eq||${filters.account}`);
    }
    if (filters.processingDateStart) {
      advancedFilters.push(
        `deposit.processingDate||gt||${filters.processingDateStart}`,
      );
    }
    if (filters.processingDateEnd) {
      const currentProcessingDateEnd = new Date(filters.processingDateEnd);
      const newProcessingDateEnd = new Date(
        currentProcessingDateEnd.setDate(
          currentProcessingDateEnd.getDate() + 1,
        ),
      );
      const day = newProcessingDateEnd.getDate();
      const month = newProcessingDateEnd.getMonth() + 1;
      const year = newProcessingDateEnd.getFullYear();
      advancedFilters.push(
        `deposit.processingDate||lt||${`${year}-${
        month < 10 ? "0" : ""
        }${month}-${day < 10 ? "0" : ""}${day}`}`,
      );
    }
    if (filters.amountMin) {
      advancedFilters.push(`deposit.adjustedAmount||gt||${filters.amountMin}`);
    }
    if (filters.amountMax) {
      advancedFilters.push(`deposit.adjustedAmount||lt||${filters.amountMax}`);
    }
    return advancedFilters;
  }

  const updateLocalStorage = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("status", status);
      localStorage.setItem("category", category);
      localStorage.setItem("fileNb", fileNb);
      const allFilters = Object.keys(filters);
      allFilters.forEach(filter => {
        localStorage.setItem(filter, filters[filter]);
      })
      localStorage.setItem("history", history);
      localStorage.setItem("openFilters", openFilters);
    }
  }

  useEffect(() => {
    const allFilters = getAdvancedFilters();
    if (fileNb) {
      allFilters.push(`file.reference||eq||${fileNb}`);
    }
    setCurrentFilters(allFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = () => {
    setFilters({
      documentNb: "",
      account: "",
      processingDateStart: "",
      processingDateEnd: "",
      amountMin: "",
      amountMax: "",
    });
  };

  const handleSort = id => {
    setOrder(id === orderBy && order === "ASC" ? "DESC" : "ASC");
    setOrderBy(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchAllFiles = withPreFilter => {
    if (accessToBothCategories) {
      setCurrentFilters(withPreFilter ? `file.status||eq||${A_TRAITER}` : null);
    } else {
      setCurrentFilters(
        withPreFilter
          ? [
            `file.status||eq||${A_TRAITER}`,
            `file.category||eq||${singleCategoryValue}`,
          ]
          : `file.category||eq||${singleCategoryValue}`,
      );
    }
  };

  const handleClickOpenFilters = () => {
    if (history) {
      if (accessToBothCategories) {
        setCategory(CATEGORIES[0].value);
      }
      setHistory(false);
      setStatus(A_TRAITER);
      searchAllFiles(true);
    }
    if (fileNb !== "") {
      setFileNb("");
      setStatus(A_TRAITER);
      searchAllFiles(true);
    }
    setOpenFilters(!openFilters);
  };

  const handleChangeStatusAndCategory = cat => (event, newStatus) => {
    setPage(0);
    if (fileNb !== "") {
      setFileNb("");
    }
    if (newStatus === MY_HISTORY.value) {
      setHistory(true);
      setStatus(STATUSES[0].value);
      if (accessToBothCategories) {
        setCategory(cat);
      }
      setCurrentFilters([
        `file.category||eq||${cat}`,
        `auditActions.authorId||eq||${userId}`,
      ]);
    } else {
      setHistory(false);
      setStatus(
        newStatus || (status === A_TRAITER ? STATUSES[0].value : A_TRAITER),
      );
      if (accessToBothCategories) {
        setCategory(newStatus ? cat : CATEGORIES[0].value);
      }
      if (newStatus) {
        setCurrentFilters([
          `file.category||eq||${cat}`,
          newStatus === EN_SUSPENS
            ? "file.status||sw||En suspens"
            : `file.status||eq||${newStatus}`,
        ]);
      } else {
        searchAllFiles(accessToBothCategories || status !== A_TRAITER);
      }
    }
    setOpenFilters(false);
    resetFilters();
  };

  const handleChangeInput = filterType => event => {
    const filter = event.target.value;
    if (filterType === "status") {
      setStatus(filter);
    } else if (filterType === "category") {
      setCategory(filter);
    } else
      setFilters({
        documentNb:
          filterType === "documentNb"
            ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            : filters.documentNb,
        account:
          filterType === "account" ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : filters.account,
        processingDateStart:
          filterType === "processingDateStart"
            ? filter
            : filters.processingDateStart,
        processingDateEnd:
          filterType === "processingDateEnd"
            ? filter
            : filters.processingDateEnd,
        amountMin:
          filterType === "amountMin"
            ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            : filters.amountMin,
        amountMax:
          filterType === "amountMax"
            ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            : filters.amountMax,
      });
  };

  const searchFiles = () => {
    setPage(0);
    setCurrentFilters(getAdvancedFilters());
  };

  const eraseFilters = () => {
    setPage(0);
    setStatus(STATUSES[0].value);
    if (accessToBothCategories) {
      setCategory(CATEGORIES[0].value);
    }
    resetFilters();
    searchAllFiles();
  };

  const handleChangeFileNb = event => {
    setFileNb(event.target.value?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  };

  useEffect(() => {
    if (currentFilters !== false) {
      setNoSearchResult(false);
      if (history) {
        FilesService.getHistoryFiles({
          filter: currentFilters,
          sort: `${orderBy}:${order}`,
          page: page + 1,
          limit: rowsPerPage,
        }).then(fs => {
          setFiles(fs.content);
          if (
            !fs.content ||
            (fs.content && Array.isArray(fs.content) && fs.content.length === 0)
          ) {
            setNoSearchResult(true);
          }
          setFilesNb(fs.metadatas && fs.metadatas.total);
        });
      } else {
        FilesService.getFiles(
          currentFilters
            ? {
              filter: currentFilters,
              sort: `${orderBy}:${order}`,
              page: page + 1,
              limit: rowsPerPage,
            }
            : {
              sort: `${orderBy}:${order}`,
              page: page + 1,
              limit: rowsPerPage,
            },
        ).then(fs => {
          setFiles(fs.content);
          if (
            !fs.content ||
            (fs.content && Array.isArray(fs.content) && fs.content.length === 0)
          ) {
            setNoSearchResult(true);
          }
          setFilesNb(fs.metadatas && fs.metadatas.total);
        });
      }
      updateLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, order, orderBy, page, rowsPerPage, history]);

  const handleClearSearch = () => {
    setFileNb("");
  };

  useEffect(() => {
    const allStatuses = STATUSES.slice(1, 4).map(st => st.value);
    FilesService.getFilesNbByStatusAndCategory({
      status: allStatuses,
      category: userCategories,
    }).then(data => {
      setProFiltersFilesNb(
        data && data[CATEGORIES[1].value] ? data[CATEGORIES[1].value] : {},
      );
      setPartFiltersFilesNb(
        data && data[CATEGORIES[2].value] ? data[CATEGORIES[2].value] : {},
      );
    });
  }, [userCategories]);

  const handleSearchByReference = () => {
    setPage(0);
    setStatus(fileNb ? STATUSES[0].value : A_TRAITER);
    setCategory(
      accessToBothCategories ? CATEGORIES[0].value : singleCategoryValue,
    );
    if (history) {
      setHistory(false);
    }
    if (fileNb) {
      setCurrentFilters(
        accessToBothCategories
          ? `file.reference||eq||${fileNb}`
          : [
            `file.category||eq||${singleCategoryValue}`,
            `file.reference||eq||${fileNb}`,
          ],
      );
    } else {
      searchAllFiles(true);
    }
  };

  const handleReferenceSearchKeyUp = event => {
    if (event.keyCode === ENTER_KEY) {
      handleSearchByReference();
    }
  };

  return (
    <div className={classes.homePage}>
      <Paper elevation={0} className={classes.paperFilters}>
        <Grid container>
          {!accessToBothCategories &&
            singleCategoryValue === CATEGORIES[1].value && (
              <Grid item xs="auto">
                <FilesToggleButtonGroup
                  title="Professionnels"
                  category={CATEGORIES[1].value}
                  status={(history && MY_HISTORY.value) || status}
                  handleChangeStatus={handleChangeStatusAndCategory}
                  filtersFilesNb={proFiltersFilesNb}
                />
              </Grid>
            )}
          {!accessToBothCategories &&
            singleCategoryValue === CATEGORIES[2].value && (
              <Grid item xs="auto">
                <FilesToggleButtonGroup
                  title="Particuliers"
                  category={CATEGORIES[2].value}
                  status={(history && MY_HISTORY.value) || status}
                  handleChangeStatus={handleChangeStatusAndCategory}
                  filtersFilesNb={partFiltersFilesNb}
                />
              </Grid>
            )}
          {accessToBothCategories && (
            <>
              <Grid item xs="auto">
                <FilesToggleButtonGroup
                  title="Professionnels"
                  double
                  category={CATEGORIES[1].value}
                  status={
                    category === CATEGORIES[1].value
                      ? (history && MY_HISTORY.value) || status
                      : STATUSES[0].value
                  }
                  handleChangeStatus={handleChangeStatusAndCategory}
                  filtersFilesNb={proFiltersFilesNb}
                />
              </Grid>
              <Grid item xs="auto">
                <Divider
                  orientation="vertical"
                  className={classes.categoryDivider}
                />
              </Grid>
              <Grid item xs="auto">
                <FilesToggleButtonGroup
                  title="Particuliers"
                  double
                  category={CATEGORIES[2].value}
                  status={
                    category === CATEGORIES[2].value
                      ? (history && MY_HISTORY.value) || status
                      : STATUSES[0].value
                  }
                  handleChangeStatus={handleChangeStatusAndCategory}
                  filtersFilesNb={partFiltersFilesNb}
                />
              </Grid>
            </>
          )}
          <Grid item xs className={classes.searchGrid}>
            <TextField
              type="text"
              disabled={openFilters}
              size="small"
              label="Recherche N° dossier"
              value={fileNb}
              className={classes.input}
              onKeyUp={handleReferenceSearchKeyUp}
              onChange={handleChangeFileNb}
              variant="outlined"
              InputProps={{
                inputProps: {
                  className: classes.searchInput,
                },
                className: openFilters ? null : classes.searchOutlinedInput,
                endAdornment: (
                  <InputAdornment position="end">
                    {fileNb && (
                      <IconButton
                        className={classes.clearIconButton}
                        onClick={handleClearSearch}
                      >
                        <Clear className={classes.clearIcon} />
                      </IconButton>
                    )}
                    <IconButton
                      className={classes.searchIconButton}
                      edge="end"
                      disabled={openFilters}
                      onClick={handleSearchByReference}
                    >
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ToggleButton
              className={classes.iconFilterButton}
              selected={openFilters}
              onClick={handleClickOpenFilters}
              value="open"
            >
              <IcoFilter className={classes.iconFilter} />
            </ToggleButton>
          </Grid>
        </Grid>
      </Paper>
      {userCategories && openFilters && (
        <FilterFields
          filters={filters}
          accessToBothCategories={accessToBothCategories}
          status={status}
          category={category}
          handleChangeInput={handleChangeInput}
          eraseFilters={eraseFilters}
          searchFiles={searchFiles}
        />
      )}
      {noSearchResult && (
        <Typography className={classes.noSearchResult} variant="h3">
          Aucun résultat ne correspond à la recherche
        </Typography>
      )}
      {!noSearchResult && (
        <Paper className={classes.paperTable}>
          <FilesTable
            files={files}
            sort={{
              order,
              orderBy,
              handleSort,
            }}
            pagination={{
              filesNb,
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
          />
        </Paper>
      )}
    </div>
  );
};

Home.propTypes = {
  userCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Home;
