import React, { useRef } from "react";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Folder from "@material-ui/icons/FolderOpenOutlined";
import CheckBox from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import Block from "@material-ui/icons/BlockOutlined";
import Comment from "@material-ui/icons/Comment";
import IcoPending from "../../icons/IcoPending";
import {
  FILE_CHANGE_STATUS,
  FILE_CONSULTATION,
  COMMENT_ADD,
  INSPECTION_CHANGE,
  ACTION_DO,
} from "../../utils/actions";
import { OK, KO, DEFAULT } from "../../utils/inspections";
import Loader from "../Loader";
import { getDate } from "../../utils/date";

const useStyles = makeStyles(theme => ({
  actionsList: {
    padding: 0,
  },
  listItem: {
    display: "block",
    padding: theme.spacing(1.5, 0, 2.5, 1.5),
    borderLeft: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  infiniteScroll: {
    height: theme.spacing(70),
    overflow: "auto",
  },
  date: {
    fontSize: "0.75rem",
    fontWeight: "normal",
  },
  icon: {
    fontSize: "1.4rem",
  },
  iconGrid: {
    display: "flex",
    paddingRight: theme.spacing(1.5),
  },
  defaultInspectionIcon: {
    fill: theme.palette.background.in_process,
  },
  koInspectionIcon: {
    color: theme.palette.background.secondary,
  },
  okInspectionIcon: {
    color: theme.palette.background.ok,
  },
  fileIcon: {
    color: theme.palette.text.primary,
  },
  actionsListTitle: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0, 2, 2.5),
    borderLeft: `1px solid ${theme.palette.border.main}`,
  },
  description: {
    lineHeight: "1.25rem",
    padding: theme.spacing(0.5, 1.5, 0.5, 0),
    marginTop: "auto",
    marginBottom: "auto",
  },
  author: {
    fontWeight: "normal",
  },
  itemInfoGrid: {
    alignItems: "center",
  },
}));

const ActionsList = props => {
  const { loadMore, listState } = props;

  const classes = useStyles();
  const scrollRef = useRef();

  return (
    <div className={classes.infiniteScroll} ref={scrollRef}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={listState.hasMoreActions}
        loader={<Loader key={0} />}
        useWindow={false}
        pageStart={0}
        getScrollParent={() => scrollRef && scrollRef.current}
      >
        <Typography variant="h3" className={classes.actionsListTitle}>
          Historique des actions sur le dossier
        </Typography>
        <List className={classes.actionsList}>
          {listState.actions &&
            Array.isArray(listState.actions) &&
            listState.actions.map(action => {
              const date = action.created ? new Date(action.created) : null;
              return (
                <ListItem className={classes.listItem} key={action.id}>
                  <Grid container className={classes.itemInfoGrid}>
                    <Grid item xs="auto" className={classes.iconGrid}>
                      {action.auditActionType === FILE_CHANGE_STATUS && (
                        <Folder
                          className={classNames(classes.icon, classes.fileIcon)}
                        />
                      )}
                      {action.auditActionType === FILE_CONSULTATION && (
                        <Folder
                          className={classNames(classes.icon, classes.fileIcon)}
                        />
                      )}
                      {action.auditActionType === COMMENT_ADD && (
                        <Comment color="primary" className={classes.icon} />
                      )}
                      {action.auditActionType === INSPECTION_CHANGE &&
                        action.inspection &&
                        ((action.inspection.status === DEFAULT && (
                          <IcoPending
                            className={classNames(
                              classes.icon,
                              classes.defaultInspectionIcon,
                            )}
                          />
                        )) ||
                          (action.inspection.status === KO && (
                            <Block
                              className={classNames(
                                classes.icon,
                                classes.koInspectionIcon,
                              )}
                            />
                          )) ||
                          (action.inspection.status === OK && (
                            <CheckCircle
                              className={classNames(
                                classes.icon,
                                classes.okInspectionIcon,
                              )}
                            />
                          )))}
                      {action.auditActionType === ACTION_DO && (
                        <CheckBox color="primary" className={classes.icon} />
                      )}
                    </Grid>
                    <Grid item xs="auto" className={classes.description}>
                      <Typography variant="h4">
                        {action.auditActionType === INSPECTION_CHANGE &&
                        action.inspection &&
                        action.inspection.label
                          ? `${action.inspection.label}: ${action.description}`
                          : action.description}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto" className={classes.description}>
                      <Typography className={classes.author}>
                        Réalisé par {action.author}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography className={classes.date}>
                    {getDate(date)}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
      </InfiniteScroll>
    </div>
  );
};

ActionsList.propTypes = {
  loadMore: PropTypes.func.isRequired,
  listState: PropTypes.shape().isRequired,
};

export default ActionsList;
