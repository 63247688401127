import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import FilterField from "./FilterField";
import { STATUSES, CATEGORIES } from "../../utils/filters";

const useStyles = makeStyles(theme => ({
  selectInput: {
    margin: theme.spacing(1, 3, 0, 0),
    height: "2.5rem",
    width: "9rem",
  },
  inputLabel: {
    fontWeight: 500,
    color: "#00008C",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

const FilterFields = props => {
  const {
    accessToBothCategories,
    filters,
    status,
    category,
    handleChangeInput,
    eraseFilters,
    searchFiles,
  } = props;

  const classes = useStyles();

  const [errorAmountMin, setErrorAmountMin] = useState(false);
  const [errorAmountMax, setErrorAmountMax] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  const eraseErrors = () => {
    setErrorAmountMax(false);
    setErrorAmountMin(false);
    setErrorDate(false);
  };

  const search = () => {
    eraseErrors();
    let error = false;
    const isNotNumberAmountMin = Number.isNaN(Number(filters.amountMin));
    const isNotNumberAmountMax = Number.isNaN(Number(filters.amountMax));
    if (isNotNumberAmountMin) {
      error = true;
      setErrorAmountMin("Veuillez saisir un nombre");
    }
    if (isNotNumberAmountMax) {
      error = true;
      setErrorAmountMax("Veuillez saisir un nombre");
    }
    if (
      !isNotNumberAmountMin &&
      !isNotNumberAmountMax &&
      parseFloat(filters.amountMin) > parseFloat(filters.amountMax)
    ) {
      error = true;
      setErrorAmountMin("Montant minimal supérieur au montant maximal");
      setErrorAmountMax(true);
    }

    if (
      filters.processingDateStart !== "" &&
      filters.processingDateEnd !== "" &&
      filters.processingDateStart > filters.processingDateEnd
    ) {
      error = true;
      setErrorDate("Date initiale supérieure à la date finale");
    }

    if (!error) {
      searchFiles();
    }
  };

  const erase = () => {
    eraseErrors();
    eraseFilters();
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs="auto">
          <InputLabel className={classes.inputLabel}>Catégorie</InputLabel>
          <Select
            className={classes.selectInput}
            value={category}
            disabled={!accessToBothCategories}
            variant="outlined"
            onChange={handleChangeInput("category")}
            displayEmpty
          >
            {CATEGORIES.map(cat => (
              <MenuItem key={cat.label} value={cat.value}>
                {cat.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs="auto">
          <FilterField
            label="N° de document"
            type="text"
            input={{
              value: filters.documentNb,
              handleChangeInput: handleChangeInput("documentNb"),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <FilterField
            label="Montant"
            type="text"
            input={{
              value: filters.amountMin,
              error: errorAmountMin,
              placeholder: "Minimal",
              handleChangeInput: handleChangeInput("amountMin"),
            }}
            secondInput={{
              secondInputError: errorAmountMax,
              secondValue: filters.amountMax,
              placeholderSecondInput: "Maximal",
              handleChangeSecondInput: handleChangeInput("amountMax"),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs="auto">
          <InputLabel className={classes.inputLabel}>Statut</InputLabel>
          <Select
            className={classes.selectInput}
            value={status}
            variant="outlined"
            onChange={handleChangeInput("status")}
            displayEmpty
          >
            {STATUSES.map(st => (
              <MenuItem key={st.label} value={st.value}>
                {st.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs="auto">
          <FilterField
            label="Compte bénéficiaire"
            type="text"
            input={{
              value: filters.account,
              handleChangeInput: handleChangeInput("account"),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <FilterField
            label="Date de traitement"
            type="date"
            input={{
              value: filters.processingDateStart,
              error: errorDate,
              handleChangeInput: handleChangeInput("processingDateStart"),
            }}
            secondInput={{
              secondValue: filters.processingDateEnd,
              secondInputError: errorDate,
              handleChangeSecondInput: handleChangeInput("processingDateEnd"),
            }}
          />
        </Grid>
        <Grid item xs className={classes.buttonDiv}>
          <div>
            <Button onClick={erase} color="primary">
              Réinitialiser les critières
            </Button>
            <Button onClick={search} variant="contained" color="primary">
              Rechercher
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

FilterFields.propTypes = {
  accessToBothCategories: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    documentNb: PropTypes.string,
    account: PropTypes.string,
    processingDateStart: PropTypes.any,
    processingDateEnd: PropTypes.any,
    amountMin: PropTypes.string,
    amountMax: PropTypes.string,
  }).isRequired,
  category: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  eraseFilters: PropTypes.func.isRequired,
  searchFiles: PropTypes.func.isRequired,
};

export default FilterFields;
