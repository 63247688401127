import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import formatter from "../../utils/amount";
import AdaptiveModal from "../Modal/AdaptiveModal";
import FileInfo from "../FileInfo";
import Status from "../Status";
import ChildrenTable from "./ChildrenTable";
import { getFileFraudStatus } from "../../utils/status";
import { getFileDate } from "../../utils/date";
import FilesService from "../../services/FilesService";

const useStyles = makeStyles(theme => ({
  boldTableCell: {
    fontWeight: "bold",
  },
  mediumTableCell: {
    fontWeight: 500,
  },
  fileInfo: {
    maxWidth: theme.spacing(180),
    maxHeight: theme.spacing(95),
    overflow: "auto",
    margin: "auto",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  row: {
    cursor: "pointer",
  },
}));

const LinkedParentsTable = props => {
  const { linkedFiles } = props;

  const classes = useStyles();

  const headCells = [
    { id: "file.reference", label: "N° Dossier" },
    { id: "file.cumulativeAmount", label: "Montant cumulé" },
    { id: "childrenCount", label: "Nb rem" },
    { id: "file.status", label: "Statut" },
    { id: "fraud", label: "Flag Fraude" },
    { id: "deposit.processingDate", label: "Date de traitement" },
  ];

  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileToShow, setFileToShow] = useState(null);
  const [inspections, setInspections] = useState(null);
  const [commentsNb, setCommentsNb] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState({
    note: "",
    advancedAmount: null,
    recoveredAmount: null,
    lastNameFirstName: "",
    chequeNumber: "",
    fraudType: null,
  });

  const handleClickFile = async file => {
    await FilesService.getFile(file.id).then(f => {
      const filterInspections =
        f.todoInspections &&
        f.todoInspections.filter(ins => ins.actionType !== "ALL_OK");
      setInspections(filterInspections);
      if (f.supplementaryDetails) {
        const { supplementaryDetails } = f;
        setAdditionalInfo({
          note: supplementaryDetails.note,
          advancedAmount: supplementaryDetails.advancedAmount,
          recoveredAmount: supplementaryDetails.recoveredAmount,
          lastNameFirstName: supplementaryDetails.lastNameFirstName,
          chequeNumber: supplementaryDetails.chequeNumber,
          fraudType: supplementaryDetails.fraudType,
        });
      }
    });
    await FilesService.getCommentsNb(file.id).then(cm => {
      setCommentsNb(cm);
    });
    setFileToShow(file);
    setOpenFileModal(true);
  };

  const childrenFilesForm = (
    <ChildrenTable childrenFullList={fileToShow && fileToShow.children} />
  );

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell key={headCell.id} align="center">
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {linkedFiles &&
            Array.isArray(linkedFiles) &&
            linkedFiles.map(linkedFile => {
              const processingDate =
                linkedFile.deposit &&
                getFileDate(linkedFile.deposit.processingDate);
              return (
                <TableRow
                  key={linkedFile.id}
                  className={classes.row}
                  onClick={() => handleClickFile(linkedFile)}
                >
                  <TableCell align="center" className={classes.boldTableCell}>
                    {linkedFile.reference}
                  </TableCell>
                  <TableCell align="right">
                    {linkedFile.cumulativeAmount &&
                      formatter.format(linkedFile.cumulativeAmount)}
                  </TableCell>
                  <TableCell align="center">
                    {linkedFile.children &&
                      Array.isArray(linkedFile.children) &&
                      linkedFile.children.length + 1}
                  </TableCell>
                  <TableCell align="center" className={classes.mediumTableCell}>
                    {linkedFile.status && <Status status={linkedFile.status} />}
                  </TableCell>
                  <TableCell align="center">
                    {getFileFraudStatus(linkedFile.provenFraud, true)}
                  </TableCell>
                  <TableCell align="center">{processingDate}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <AdaptiveModal
        openModal={openFileModal}
        handleClose={() => setOpenFileModal(false)}
        title={fileToShow && `Dossier ${fileToShow.reference}`}
        width={fileToShow && fileToShow.parentId ? 115 : 185}
        withDivider={false}
      >
        <div className={classes.fileInfo}>
          {fileToShow && (
            <>
              <Typography>
                Date de traitement:{" "}
                {fileToShow.deposit && fileToShow.deposit.processingDate
                  ? new Date(
                      fileToShow.deposit.processingDate,
                    ).toLocaleDateString()
                  : ""}
              </Typography>
              <FileInfo
                file={fileToShow}
                inspectionsForm={
                  fileToShow.parentId
                    ? null
                    : {
                        inspections,
                        commentsNb,
                      }
                }
                additionalInfoForm={
                  fileToShow.parentId
                    ? null
                    : {
                        additionalInfo,
                      }
                }
                childrenFiles={childrenFilesForm}
              />
            </>
          )}
        </div>
      </AdaptiveModal>
    </div>
  );
};

LinkedParentsTable.propTypes = {
  linkedFiles: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default LinkedParentsTable;
