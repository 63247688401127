import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  EN_SUSPENS_DEMANDE_TIERS,
  EN_SUSPENS_FRAUDE_AVEREE,
  FRAUDE_AVEREE,
  FRAUDE_NON_AVEREE,
} from "../utils/status";

const useStyles = makeStyles(theme => ({
  statusPaper: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2, 0, 1.5, 2),
  },
  statusRadioGroup: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
  statusTypography: {
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.primary,
  },
  radioButton: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  radioLabel: {
    fontWeight: "normal",
  },
}));

const ChangeStatusForm = props => {
  const {
    fileStatus,
    handleChangeStatus,
    closureStatus,
    handleChangeClosureStatus,
  } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.statusPaper}>
      <Typography variant="h2">Statut du dossier</Typography>
      <div className={classes.statusRadioGroup}>
        <Typography variant="h4" className={classes.statusTypography}>
          En suspens :
        </Typography>
        <RadioGroup value={fileStatus} onChange={handleChangeStatus}>
          <FormControlLabel
            value={EN_SUSPENS_DEMANDE_TIERS}
            control={
              <Radio
                size="small"
                color="primary"
                className={classes.radioButton}
              />
            }
            label={
              <Typography className={classes.radioLabel}>
                Demande tiers / suspicion de fraude
              </Typography>
            }
          />
          <FormControlLabel
            value={EN_SUSPENS_FRAUDE_AVEREE}
            control={
              <Radio
                size="small"
                color="secondary"
                className={classes.radioButton}
              />
            }
            label={
              <Typography className={classes.radioLabel}>
                Fraude avérée
              </Typography>
            }
          />
        </RadioGroup>
      </div>
      <div className={classes.statusRadioGroup}>
        <Typography variant="h4" className={classes.statusTypography}>
          Clôturer le dossier :
        </Typography>
        <RadioGroup value={closureStatus} onChange={handleChangeClosureStatus}>
          <FormControlLabel
            value={FRAUDE_AVEREE}
            control={
              <Radio
                size="small"
                color="primary"
                className={classes.radioButton}
              />
            }
            label={
              <Typography className={classes.radioLabel}>
                Fraude confirmée
              </Typography>
            }
          />
          <FormControlLabel
            value={FRAUDE_NON_AVEREE}
            control={
              <Radio
                size="small"
                color="primary"
                className={classes.radioButton}
              />
            }
            label={
              <Typography className={classes.radioLabel}>
                Pas de fraude détectée
              </Typography>
            }
          />
        </RadioGroup>
      </div>
    </Paper>
  );
};

ChangeStatusForm.propTypes = {
  fileStatus: PropTypes.string.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  closureStatus: PropTypes.string.isRequired,
  handleChangeClosureStatus: PropTypes.func.isRequired,
};

export default ChangeStatusForm;
