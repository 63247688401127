import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles(() => ({
  categoryButtonsGroup: {
    display: "flex",
    justifyContent: "center",
  },
}));

const BlockingToggle = props => {
  const classes = useStyles();

  const { handleBlockingClick, inspectionId, blocking } = props;

  return (
    <div className={classes.categoryButtonsGroup}>
      <ToggleButtonGroup
        value={blocking}
        exclusive
        onChange={handleBlockingClick(inspectionId)}
      >
        <ToggleButton disableRipple value={false}>
          Non bloquant
        </ToggleButton>
        <ToggleButton disableRipple value>
          Bloquant
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

BlockingToggle.propTypes = {
  handleBlockingClick: PropTypes.func.isRequired,
  inspectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  blocking: PropTypes.bool.isRequired,
};

export default BlockingToggle;
