import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  actionLabel: {
    width: "17.5rem",
    height: "2.3rem",
  },
}));

const ActionSelect = props => {
  const classes = useStyles();

  const { inspectionId, actions, availableAction, handleChangeAction } = props;

  return (
    <Select
      value={availableAction.id}
      variant="outlined"
      className={classes.actionLabel}
      onChange={handleChangeAction(inspectionId)}
    >
      {actions.map(action => (
        <MenuItem key={action.id} value={action.id}>
          {action.label}
        </MenuItem>
      ))}
    </Select>
  );
};

ActionSelect.propTypes = {
  inspectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  availableAction: PropTypes.shape().isRequired,
  handleChangeAction: PropTypes.func.isRequired,
};

export default ActionSelect;
