import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import formatter from "../../utils/amount";

const useStyles = makeStyles(theme => ({
  chequesTable: {
    padding: 0,
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  },
  chequesRow: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  chequesCell: {
    padding: theme.spacing(1, 0.25),
    border: "none",
    whiteSpace: "nowrap",
  },
  amountCell: {
    textAlign: "right",
  },
}));

const ChequesTable = props => {
  const { cheques } = props;

  const classes = useStyles();

  return (
    <Table className={classes.chequesTable}>
      <TableBody>
        {cheques &&
          Array.isArray(cheques) &&
          cheques.map(ch => (
            <TableRow key={ch.id} className={classes.chequesRow}>
              <TableCell className={classes.chequesCell}>
                <Typography>{ch.reference} : </Typography>
              </TableCell>
              <TableCell
                className={classNames(classes.chequesCell, classes.amountCell)}
              >
                <Typography> {formatter.format(ch.amount)}</Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

ChequesTable.propTypes = {
  cheques: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ChequesTable;
