import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Status from "../Status";
import formatter from "../../utils/amount";
import {
  ECARTEE,
  FRAUDE,
  DATE,
  REFERENCE,
  STATUS,
  MOTIF,
  AMOUNT,
} from "../../utils/statistics";
import { getFileFraudStatus } from "../../utils/status";
import { getFileDate } from "../../utils/date";

const useStyles = makeStyles(theme => ({
  table: {
    display: "block",
    overflow: "auto",
  },
  largerFontTableCell: {
    fontSize: "1rem",
    lineHeigth: "1.25rem",
  },
  boldTableCell: {
    fontWeight: "bold",
  },
  lightTableCell: {
    color: theme.palette.text.primary,
  },
  mediumCell: {
    fontWeight: 500,
  },
  tableCell: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rejectionMotifCell: {
    minWidth: "17.5rem",
  },
  statusTableCell: {
    minWidth: "9rem",
  },
}));

const FilesStatsTable = props => {
  const { files, headers, sort, pagination } = props;
  const classes = useStyles();

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} sur ${count}`;

  const headersLabels = Object.keys(headers);

  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headersLabels &&
              Array.isArray(headersLabels) &&
              headersLabels.map(headCell => {
                const headerId = headers[headCell];
                return (
                  <TableCell
                    align="center"
                    key={headCell}
                    className={classes.mediumCell}
                  >
                    {headerId && (
                      <TableSortLabel
                        active={sort.orderBy === headerId}
                        direction={
                          sort.orderBy === headerId
                            ? sort.order.toLowerCase()
                            : "asc"
                        }
                        onClick={() => sort.handleSort(headerId)}
                      >
                        {headCell}
                        {sort.orderBy === headerId ? (
                          <span className={classes.visuallyHidden}>
                            {sort.order === "DESC"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    )}
                    {!headerId && headCell}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {files &&
            Array.isArray(files) &&
            files.map(file => {
              return (
                <TableRow key={Math.random()}>
                  {headersLabels &&
                    Array.isArray(headersLabels) &&
                    headersLabels.map(cell => {
                      const fileCell = file[cell];
                      if (file[cell]) {
                        if (cell === ECARTEE) {
                          return (
                            <TableCell
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.mediumCell,
                              )}
                            >
                              {fileCell ? "Oui" : "Non"}
                            </TableCell>
                          );
                        }
                        if (cell === DATE) {
                          return (
                            <TableCell
                              align="center"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.mediumCell,
                              )}
                            >
                              {getFileDate(fileCell)}
                            </TableCell>
                          );
                        }
                        if (cell === FRAUDE) {
                          return (
                            <TableCell
                              align="center"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.mediumCell,
                              )}
                            >
                              {getFileFraudStatus(fileCell, false)}
                            </TableCell>
                          );
                        }
                        if (cell === STATUS) {
                          return (
                            <TableCell
                              align="center"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.boldTableCell,
                                classes.statusTableCell,
                              )}
                            >
                              <Status status={fileCell} />
                            </TableCell>
                          );
                        }
                        if (cell === REFERENCE) {
                          return (
                            <TableCell
                              align="center"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.boldTableCell,
                                classes.largerFontTableCell,
                              )}
                            >
                              {fileCell}
                            </TableCell>
                          );
                        }
                        if (cell === MOTIF) {
                          return (
                            <TableCell
                              align="center"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.lightTableCell,
                                classes.rejectionMotifCell,
                              )}
                            >
                              {fileCell}
                            </TableCell>
                          );
                        }
                        if (cell === AMOUNT) {
                          return (
                            <TableCell
                              align="right"
                              key={cell}
                              className={classNames(
                                classes.tableCell,
                                classes.largerFontTableCell,
                              )}
                            >
                              {fileCell && formatter.format(fileCell)}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            align="center"
                            key={cell}
                            className={classNames(
                              classes.tableCell,
                              classes.mediumCell,
                            )}
                          >
                            {fileCell}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={cell} className={classes.tableCell}>
                          {null}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={pagination.filesNb}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onChangePage={pagination.handleChangePage}
        onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page: "
        labelDisplayedRows={labelDisplayedRows}
      />
    </div>
  );
};

FilesStatsTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  headers: PropTypes.shape().isRequired,
  sort: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    handleSort: PropTypes.func.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    filesNb: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilesStatsTable;
