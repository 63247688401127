// "value" prendra ensuite la valeur demandé par l'API

export const STATUSES = [
  {
    label: "Tous",
    value: "",
  },
  {
    label: "À traiter",
    value: "A traiter",
  },
  {
    label: "En cours",
    value: "En cours",
  },
  {
    label: "En suspens",
    value: "En suspens",
  },
  {
    label: "Clos",
    value: "Clos",
  },
];

export const CATEGORIES = [
  {
    label: "Toutes",
    value: "",
  },
  {
    label: "PRO",
    value: "PRO",
  },
  {
    label: "PART",
    value: "PART",
  },
];

export const MY_HISTORY = {
  label: "Mon historique",
  value: "Mon historique",
};
