import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import InspectionsService from "../services/InspectionsService";
import { PRO, PART, ALL_OK, INSPECTION_KO } from "../utils/inspections";
import AlertDialog from "../components/Dialog/AlertDialog";
import history from "./history";
import AdministrationInspections from "../components/Table/AdministrationInspections";
import AdaptiveModal from "../components/Modal/AdaptiveModal";
import ActionsService from "../services/ActionsService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  paperTable: {
    maxWidth: theme.spacing(190),
    margin: "auto",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  saveButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionModal: {
    paddingTop: theme.spacing(2.5),
  },
  actionLabel: {
    width: "17.5rem",
    height: "2.3rem",
  },
  actionButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1.5),
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const Inspections = () => {
  const classes = useStyles();

  const [defaultInspections, setDefaultInspections] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [allOkDefaultInspections, setAllOkDefaultInspections] = useState([]);
  const [allOkInspections, setAllOkInspections] = useState([]);
  const [addedInspections, setAddedInspections] = useState([]);

  const [actions, setActions] = useState();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [newLocation, setNewLocation] = useState();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [inspectionToDelete, setInspectionToDelete] = useState({
    inspectionId: null,
    newInspection: false,
  });

  const [openAddActionModal, setOpenAddActionModal] = useState(false);
  const [newActionToAdd, setNewActionToAdd] = useState("");
  const [addActionError, setAddActionError] = useState(false);

  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);
  const [actionToDelete, setActionToDelete] = useState("");
  const [deleteActionError, setDeleteActionError] = useState(false);

  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  const ENTER_KEY = 13;
  const offset = 2;

  useEffect(() => {
    if (openAddActionModal) {
      setNewActionToAdd("");
      setAddActionError(false);
    }
  }, [openAddActionModal]);

  useEffect(() => {
    if (openDeleteActionModal) {
      setDeleteActionError(false);
      setActionToDelete("");
    }
  }, [openDeleteActionModal]);

  const getInspections = () => {
    InspectionsService.getInspections().then(ins => {
      setAddedInspections([]);
      if (ins && ins.content) {
        const newInpections = ins.content.filter(
          inspection => inspection.actionType !== ALL_OK,
        );
        const newAllOkInspections = ins.content.filter(
          inspection => inspection.actionType === ALL_OK,
        );
        setDefaultInspections(newInpections);
        setInspections(newInpections);
        setAllOkDefaultInspections(newAllOkInspections);
        setAllOkInspections(newAllOkInspections);
      }
    });
  };

  useEffect(() => {
    getInspections();
  }, []);

  useEffect(() => {
    setShowAlert(
      defaultInspections !== inspections ||
        allOkDefaultInspections !== allOkInspections ||
        (addedInspections && addedInspections.length !== 0),
    );
  }, [
    defaultInspections,
    inspections,
    allOkDefaultInspections,
    allOkInspections,
    addedInspections,
  ]);

  // eslint-disable-next-line consistent-return
  window.onbeforeunload = () => {
    if (showAlert && !isConfirmed) {
      return false;
    }
  };

  const getActions = () => {
    InspectionsService.getActions().then(act => {
      setActions(act && act.content);
    });
  };

  useEffect(() => {
    getActions();
  }, []);

  const handleChangeLabel = id => event => {
    const newInspections = inspections.map(ins => {
      if (ins.id !== id) {
        return ins;
      }
      return {
        ...ins,
        label: event.target.value,
      };
    });
    setInspections(newInspections);
  };

  const handleClickDisplayField = id => event => {
    const newInspections = inspections.map(ins => {
      if (ins.id !== id) {
        return ins;
      }
      return {
        ...ins,
        displayInputField: event.target.checked,
      };
    });
    setInspections(newInspections);
  };

  const handleBlockingClick = id => (event, blocking) => {
    if (blocking !== null) {
      const newInspections = inspections.map(ins => {
        if (ins.id !== id) {
          return ins;
        }
        return {
          ...ins,
          blocking,
        };
      });
      setInspections(newInspections);
    }
  };

  const handleChangeAction = id => event => {
    const actionId = event.target.value;
    const newInspections = inspections.map(ins => {
      if (
        ins.id !== id ||
        (ins.availableAction && ins.availableAction.id === actionId)
      ) {
        return ins;
      }
      const newAction = actions.find(act => act.id === actionId);
      return {
        ...ins,
        availableAction: newAction,
      };
    });
    setInspections(newInspections);
  };

  const handleCategoryClick = id => event => {
    const category = event.target.value;
    const newInspections = inspections.map(ins => {
      if (ins.id !== id) {
        return ins;
      }
      if (ins.categories && ins.categories.length === 1) {
        if (ins.categories.includes(category)) {
          return ins;
        }
        return {
          ...ins,
          categories: [PRO, PART],
        };
      }
      return {
        ...ins,
        categories: category === PRO ? [PART] : [PRO],
      };
    });
    setInspections(newInspections);
  };

  const handleClickSave = async () => {
    if (inspections !== defaultInspections) {
      let response = null;
      await Promise.all(
        defaultInspections.map(di => {
          const ins = inspections.find(i => di.id === i.id);
          let data = {};
          if (ins.label !== di.label) {
            data = { ...data, label: ins.label };
          }
          if (ins.blocking !== di.blocking) {
            data = { ...data, blocking: ins.blocking };
          }
          if (ins.displayInputField !== di.displayInputField) {
            data = { ...data, displayInputField: ins.displayInputField };
          }
          if (ins.categories !== di.categories) {
            data = { ...data, categories: ins.categories };
          }
          if (
            ins.availableAction &&
            di.availableAction &&
            ins.availableAction.id !== di.availableAction.id
          ) {
            data = { ...data, availableAction: ins.availableAction };
          }
          if (Object.keys(data).length !== 0) {
            response = InspectionsService.updateInspection({
              inspectionId: ins.id,
              data,
            });
            return response;
          }
          return response;
        }),
      );
    }
    if (allOkInspections !== allOkDefaultInspections) {
      let response = null;
      await Promise.all(
        allOkDefaultInspections.map(di => {
          const inspection = allOkInspections.find(i => di.id === i.id);
          if (!inspection) {
            response = InspectionsService.deleteInspection(di.id);
          }
          return response;
        }),
      );
      response = null;
      await Promise.all(
        allOkDefaultInspections.map(di => {
          const inspection = allOkInspections.find(i => di.id === i.id);
          if (inspection && inspection !== di) {
            let data = {};
            if (inspection.categories !== di.categories) {
              data = { ...data, categories: inspection.categories };
            }
            if (inspection.actionLabel !== di.actionLabel) {
              data = { ...data, actionLabel: inspection.actionLabel };
            }
            response = InspectionsService.updateInspection({
              inspectionId: di.id,
              data,
            });
          }
          return response;
        }),
      );
      response = null;
      await Promise.all(
        allOkInspections.map(ins => {
          const inspection = allOkDefaultInspections.find(
            di => ins.id === di.id,
          );
          if (!inspection) {
            response = InspectionsService.addInspection({
              label: ins.label,
              significance: ins.significance,
              displayInputField: false,
              blocking: false,
              categories: ins.categories,
              actionType: ALL_OK,
              actionLabel: ins.actionLabel,
              availableAction: null,
            });
          }
          return response;
        }),
      );
    }
    if (addedInspections && addedInspections.length !== 0) {
      let response = null;
      await Promise.all(
        addedInspections.map(ins => {
          const addedInspection = ins;
          response = InspectionsService.addInspection(addedInspection);
          return response;
        }),
      );
    }
    if (
      inspections !== defaultInspections ||
      allOkInspections !== allOkDefaultInspections ||
      (addedInspections && addedInspections.length !== 0)
    ) {
      getInspections();
    }
  };

  const handleDelete = async inspectionId => {
    await InspectionsService.deleteInspection(inspectionId);
    const withoutDeletedInspection = inspections.filter(
      ins => ins.id !== inspectionId,
    );
    let i = offset;
    // eslint-disable-next-line no-restricted-syntax
    for (const ins of withoutDeletedInspection) {
      if (ins.significance !== i) {
        // eslint-disable-next-line no-await-in-loop
        await InspectionsService.updateInspection({
          inspectionId: ins.id,
          data: { significance: i },
        });
      }
      i += 1;
    }
    const updatedInspections = withoutDeletedInspection.map((ins, index) => {
      if (ins.significance === index + offset) {
        return ins;
      }
      return {
        ...ins,
        significance: index + offset,
      };
    });
    setInspections(updatedInspections);
    if (inspections === defaultInspections) {
      setDefaultInspections(updatedInspections);
    } else {
      const defaultWithoutDeletedInspection = defaultInspections.filter(
        ins => ins.id !== inspectionId,
      );
      const updatedDefaultInspections = defaultWithoutDeletedInspection.map(
        (ins, index) => {
          if (ins.significance === index + offset) {
            return ins;
          }
          return {
            ...ins,
            significance: index + offset,
          };
        },
      );
      setDefaultInspections(updatedDefaultInspections);
    }
    const updateAddedInspections =
      addedInspections &&
      Array.isArray(addedInspections) &&
      addedInspections.map(ins => {
        return {
          ...ins,
          significance: ins.significance - 1,
        };
      });
    setAddedInspections(updateAddedInspections);
  };

  const handleClickAddInspection = () => {
    if (actions && Array.isArray(actions) && actions.length > 0) {
      const addedInspectionsLength =
        addedInspections &&
        Array.isArray(addedInspections) &&
        addedInspections.length;
      let inspectionSignificance;
      if (addedInspectionsLength === 0 || !addedInspectionsLength) {
        const inspectionsLength =
          inspections && Array.isArray(inspections) && inspections.length;
        inspectionSignificance =
          inspectionsLength && inspectionsLength !== 0
            ? inspections[inspectionsLength - 1].significance + 1
            : offset;
      } else {
        inspectionSignificance =
          addedInspections[addedInspectionsLength - 1].significance + 1;
      }
      const newInspection = {
        label: "",
        significance: inspectionSignificance,
        blocking: false,
        displayInputField: false,
        categories: [PRO, PART],
        actionType: INSPECTION_KO,
        actionLabel: null,
        availableAction:
          actions && Array.isArray(actions) && actions.length !== 0
            ? actions[0]
            : null,
      };
      const newInspections = [...addedInspections, newInspection];
      setAddedInspections(newInspections);
    } else {
      setOpenErrorMessage(true);
    }
  };

  const handleChangeActionLabel = inspectionIndex => event => {
    const newInspections = allOkInspections.map((ins, index) => {
      if (index !== inspectionIndex) {
        return ins;
      }
      return {
        ...ins,
        actionLabel: event.target.value,
      };
    });
    setAllOkInspections(newInspections);
  };

  const handleChangeAddedInspectionLabel = inspectionIndex => event => {
    const newInspections = addedInspections.map((ins, index) => {
      if (index !== inspectionIndex) {
        return ins;
      }
      return {
        ...ins,
        label: event.target.value,
      };
    });
    setAddedInspections(newInspections);
  };

  const handleAddedInspectionDisplayField = inspectionIndex => event => {
    const newInspections = addedInspections.map((ins, index) => {
      if (index !== inspectionIndex) {
        return ins;
      }
      return {
        ...ins,
        displayInputField: event.target.checked,
      };
    });
    setAddedInspections(newInspections);
  };

  const handleAddedInspectionBlockingClick = inspectionIndex => (
    event,
    blocking,
  ) => {
    if (blocking !== null) {
      const newInspections = addedInspections.map((ins, index) => {
        if (index !== inspectionIndex) {
          return ins;
        }
        return {
          ...ins,
          blocking,
        };
      });
      setAddedInspections(newInspections);
    }
  };

  const handleAddedInspectionCategoryClick = inspectionIndex => event => {
    const category = event.target.value;
    const newInspections = addedInspections.map((ins, index) => {
      if (index !== inspectionIndex) {
        return ins;
      }
      if (ins.categories && ins.categories.length === 1) {
        if (ins.categories.includes(category)) {
          return ins;
        }
        return {
          ...ins,
          categories: [PRO, PART],
        };
      }
      return {
        ...ins,
        categories: category === PRO ? [PART] : [PRO],
      };
    });
    setAddedInspections(newInspections);
  };

  const handleChangeAddedInspectionAction = inspectionIndex => event => {
    const actionId = event.target.value;
    const newInspections = addedInspections.map((ins, index) => {
      if (
        index !== inspectionIndex ||
        (ins.availableAction && ins.availableAction.id === actionId)
      ) {
        return ins;
      }
      const newAction = actions.find(act => act.id === actionId);
      return {
        ...ins,
        availableAction: newAction,
      };
    });
    setAddedInspections(newInspections);
  };

  const handleDeleteAddedInspection = inspectionIndex => {
    const newInspections = addedInspections.filter(
      (ins, index) => index !== inspectionIndex,
    );
    const inspectionsLength = inspections.length;
    const updatedDefaultInspections = newInspections.map((ins, index) => {
      if (ins.significance === inspectionsLength + index + offset) {
        return ins;
      }
      return {
        ...ins,
        significance: inspectionsLength + index + offset,
      };
    });
    setAddedInspections(updatedDefaultInspections);
  };

  const handleCloseExitModal = () => {
    setNewLocation();
    setOpenExitModal(false);
  };

  const handleQuitWithoutSaving = () => {
    setIsConfirmed(true);
    setOpenExitModal(false);
  };

  const handleQuit = nextLocation => {
    if (!isConfirmed && nextLocation.pathname !== "/inspections") {
      setNewLocation(nextLocation);
      setOpenExitModal(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isConfirmed) {
      if (newLocation) {
        history.push(newLocation.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  const handleAllOkCategoryClick = index => event => {
    const category = event.target.value;
    const allOkInspection = allOkInspections[index];
    let data;
    if (allOkInspection.categories && allOkInspection.categories.length === 2) {
      data = [
        {
          id: allOkInspection.id,
          significance: allOkInspection.significance,
          label: allOkInspection.label,
          categories: [category === PRO ? PART : PRO],
          actionLabel: allOkInspection.actionLabel,
        },
        {
          label: allOkInspection.label,
          significance: allOkInspection.significance === 0 ? 1 : 0,
          categories: [category],
          actionLabel: "",
        },
      ];
    } else if (
      allOkInspection.categories &&
      allOkInspection.categories[0] === category
    ) {
      data = allOkInspections;
    } else {
      data = [
        {
          id: allOkInspections[0].id,
          label: allOkInspection.label,
          significance: allOkInspection.significance,
          categories: [PRO, PART],
          actionLabel: allOkInspection.actionLabel,
        },
      ];
    }
    setAllOkInspections(data);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleDeleteInspection = () => {
    setOpenConfirmationModal(false);
    if (inspectionToDelete.newInspection === true) {
      handleDeleteAddedInspection(inspectionToDelete.inspectionId);
    } else {
      handleDelete(inspectionToDelete.inspectionId);
    }
  };

  const handleClickDelete = (inspectionId, newInspection) => {
    setOpenConfirmationModal(true);
    setInspectionToDelete({
      inspectionId,
      newInspection,
    });
  };

  const handleCloseAddActionModal = () => {
    setOpenAddActionModal(false);
  };

  const handleOpenAddActionModal = () => {
    setOpenAddActionModal(true);
  };

  const handleChangeNewAction = event => {
    setNewActionToAdd(event.target.value);
  };

  const handleAddAction = async () => {
    if (newActionToAdd !== "") {
      await ActionsService.addAction(newActionToAdd);
      getActions();
      setOpenAddActionModal(false);
    } else {
      setAddActionError(true);
    }
  };

  const handleCloseDeleteActionModal = () => {
    setOpenDeleteActionModal(false);
  };

  const handleChangeActionToDelete = event => {
    setActionToDelete(event.target.value);
  };

  const handleDeleteAction = async () => {
    if (actionToDelete !== "") {
      await ActionsService.deleteAction(actionToDelete.id);
      getActions();
      setOpenDeleteActionModal(false);
    } else {
      setDeleteActionError(true);
    }
  };

  const handleOpenDeleteActionModal = () => {
    setOpenDeleteActionModal(true);
  };

  const handleAddKeyUp = event => {
    if (event.keyCode === ENTER_KEY && newActionToAdd) {
      handleAddAction();
    }
  };

  const handleCloseErrorMessage = () => {
    setOpenErrorMessage(false);
  };

  return (
    <Paper className={classes.paperTable}>
      <AlertDialog
        title="Attention"
        contentText="Des modifications ont été apportées et doivent être enregistrées pour
      continuer. Si vous poursuivez, ces modifications seront perdues.
      Souhaitez-vous continuer?"
        openClose={openExitModal}
        handleClose={handleCloseExitModal}
        quitWithoutSaving={handleQuitWithoutSaving}
        closeDialog={handleCloseExitModal}
        agree="Oui"
        disagree="Non"
      />
      <Prompt when={showAlert} message={handleQuit} />
      <AdministrationInspections
        inspections={inspections}
        handleInspections={{
          handleChangeLabel,
          handleBlockingClick,
          handleClickDisplayField,
          handleCategoryClick,
          handleChangeAction,
        }}
        addedInspections={addedInspections}
        handleAddedInspections={{
          handleChangeAddedInspectionLabel,
          handleAddedInspectionBlockingClick,
          handleAddedInspectionDisplayField,
          handleAddedInspectionCategoryClick,
          handleChangeAddedInspectionAction,
        }}
        allOkInspections={allOkInspections}
        handleAllOkInspections={{
          handleAllOkCategoryClick,
          handleChangeActionLabel,
        }}
        handleClickAddInspection={handleClickAddInspection}
        handleClickDelete={handleClickDelete}
        actions={actions}
        handleOpenAddActionModal={handleOpenAddActionModal}
        handleOpenDeleteActionModal={handleOpenDeleteActionModal}
      />
      <div className={classes.saveButtonDiv}>
        <Button variant="contained" color="primary" onClick={handleClickSave}>
          Enregistrer
        </Button>
      </div>
      <AlertDialog
        title="Attention"
        contentText="Êtes-vous sûr de vouloir supprimer ce contrôle?"
        openClose={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        quitWithoutSaving={handleDeleteInspection}
        closeDialog={handleCloseConfirmationModal}
        agree="Supprimer"
        disagree="Non"
      />
      <AdaptiveModal
        width={60}
        openModal={openAddActionModal}
        handleClose={handleCloseAddActionModal}
        title="Ajouter une action"
      >
        <div className={classes.actionModal}>
          <InputLabel className={classes.inputLabel}>
            Nom d&apos;action:
          </InputLabel>
          <TextField
            variant="outlined"
            size="small"
            autoFocus
            error={addActionError}
            onKeyUp={handleAddKeyUp}
            InputProps={{
              className: classNames(classes.actionLabel),
            }}
            value={newActionToAdd}
            onChange={handleChangeNewAction}
          />
          <div className={classes.actionButtonDiv}>
            <Button onClick={handleAddAction} color="primary">
              Ajouter
            </Button>
          </div>
        </div>
      </AdaptiveModal>

      <AdaptiveModal
        width={60}
        openModal={openDeleteActionModal}
        handleClose={handleCloseDeleteActionModal}
        title="Supprimer une action"
      >
        <div className={classes.actionModal}>
          <InputLabel className={classes.inputLabel}>
            Nom d&apos;action:
          </InputLabel>
          <Select
            variant="outlined"
            size="small"
            autoFocus
            className={classes.actionLabel}
            value={actionToDelete}
            onChange={handleChangeActionToDelete}
            error={deleteActionError}
          >
            {actions &&
              Array.isArray(actions) &&
              actions.map(action => (
                <MenuItem key={action.id} value={action}>
                  {action.label}
                </MenuItem>
              ))}
          </Select>
          <div className={classes.actionButtonDiv}>
            <Button onClick={handleDeleteAction} color="primary">
              Supprimer
            </Button>
          </div>
        </div>
      </AdaptiveModal>
      <Snackbar
        open={openErrorMessage}
        autoHideDuration={10000}
        onClose={handleCloseErrorMessage}
        ClickAwayListenerProps={{
          onClickAway: null
        }}
      >
        <Alert onClose={handleCloseErrorMessage} severity="error">
          Veuillez renseigner au moins une action afin de pouvoir ajouter un
          contrôle
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Inspections;
