import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ChequesTable from "./Table/ChequesTable";
import ChequesInfos from "./ChequesInfos";
import AdaptiveModal from "./Modal/AdaptiveModal";
import FilesService from "../services/FilesService";
import formatter from "../utils/amount";
import InspectionsTable from "./Table/InspectionsTable";
import ChangeStatusForm from "./ChangeStatusForm";
import AdditionalFileInfo from "./AdditionalFileInfo";
import Status from "./Status";
import { DEPOSIT, CHEQUE, RECTO, VERSO } from "../utils/cheque";

const useStyles = makeStyles(theme => ({
  paperFile: {
    margin: theme.spacing(2.5, 0),
    padding: theme.spacing(0.5, 3, 3, 3),
  },
  pageGrid: {
    maxWidth: theme.spacing(172),
    margin: "auto",
    marginTop: theme.spacing(1),
  },
  sizedPaperGrids: {
    display: "flex",
    flexDirection: "column",
  },
  photoGrid: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3.5, 0),
    height: "100%",
  },
  chequesGrid: {
    padding: theme.spacing(2, 2.5, 1, 2.5),
    height: "100%",
  },
  centeredGrid: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  photoButton: {
    borderRadius: 0,
  },
  imgPhoto: {
    width: "100%",
  },
  notFoundImage: {
    width: theme.spacing(36),
    color: theme.palette.text.primary,
  },
  rejected: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.error,
    fontWeight: 600,
  },
  notRejected: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.ok,
    fontWeight: 600,
  },
  rejectionMotif: {
    width: theme.spacing(27),
  },
  additionalInfoGrid: {
    marginBottom: theme.spacing(1),
  },
  infoFirstColumn: {
    paddingRight: theme.spacing(2.5),
  },
  fileInfoLabel: {
    margin: theme.spacing(2, 0, 0.5, 0),
  },
  chequesLabelGrid: {
    display: "flex",
    justifyContent: "center",
  },
  chequesLabel: {
    fontWeight: 600,
  },
}));

const FileInfo = props => {
  const {
    file,
    children,
    inspectionsForm,
    additionalInfoForm,
    statusForm,
    linkedFiles,
    childrenFiles,
  } = props;

  const classes = useStyles();

  const [slipPhotoURL, setSlipPhotoURL] = useState("");
  const [openPhotosModal, setOpenPhotosModal] = useState(false);
  const [notFoundSlipImage, setNotFoundSlipImage] = useState(false);
  const [photosCount, setPhotosCount] = useState(0);
  const [rectoPhoto, setRectoPhoto] = useState(true);
  const [photoURL, setPhotoURL] = useState("");

  const [cheques, setCheques] = useState([]);
  const [chequesNb, setChequesNb] = useState(0);

  const isChildren = !!file.parentId;

  useEffect(() => {
    FilesService.getPicture({
      fileId: file.id,
      type: DEPOSIT,
    }).then(res => {
      const { data } = res;
      const type = res.headers && res.headers["content-type"];
      const photo = new Blob([data], {
        type,
      });
      setSlipPhotoURL(URL.createObjectURL(photo));
    });
  }, [file.id]);

  useEffect(() => {
    if (file.deposit) {
      FilesService.getCheques(file.deposit.id).then(ch => {
        setCheques(ch.content);
        setChequesNb(ch.metadatas && ch.metadatas.total);
      });
    }
  }, [file.deposit]);

  const handleOpenPhotosModal = () => {
    setOpenPhotosModal(true);
  };

  const handleNotFoundSlipImage = () => {
    setNotFoundSlipImage(true);
  };

  useEffect(() => {
    setNotFoundSlipImage(false);
  }, [slipPhotoURL]);

  useEffect(() => {
    const pictureId =
      cheques &&
      Array.isArray(cheques) &&
      cheques[photosCount] &&
      cheques[photosCount].pictureId;
    if (pictureId) {
      FilesService.getPicture({
        fileId: file.id,
        type: CHEQUE,
        reference: pictureId,
        side: rectoPhoto ? RECTO : VERSO,
      }).then(res => {
        const { data } = res;
        const photo = new Blob([data], { type: document.type });
        setPhotoURL(URL.createObjectURL(photo));
      });
    }
  }, [file.id, cheques, photosCount, rectoPhoto]);

  const handleSwitch = sens => {
    setPhotosCount(sens === "left" ? photosCount - 1 : photosCount + 1);
    setRectoPhoto(true);
  };

  const handleCloseChequesModal = () => {
    setPhotosCount(0);
    setRectoPhoto(true);
    setOpenPhotosModal(false);
  };

  const handleChangeRectoVerso = () => {
    setRectoPhoto(!rectoPhoto);
  };

  return (
    <Grid container className={classes.pageGrid}>
      <Grid item xs={isChildren ? 12 : 8}>
        <div className={classes.infoFirstColumn}>
          <Paper className={classes.paperFile}>
            <Grid container justify="space-between">
              <Grid item xs="auto">
                <Typography className={classes.fileInfoLabel}>
                  Numéro de compte
                </Typography>
                <Typography variant="h3">
                  {file.deposit && file.deposit.beneficiaryAccount}
                </Typography>
                <Typography className={classes.fileInfoLabel}>
                  Statut
                </Typography>
                <Typography variant="h3">
                  {file.status && <Status status={file.status} />}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography className={classes.fileInfoLabel}>
                  Nombre de chèques
                </Typography>
                <Typography variant="h3">
                  {file.deposit && file.deposit.chequeNumber}
                </Typography>
                {file.rejected && (
                  <>
                    <Typography className={classes.fileInfoLabel}>
                      Motif de l&apos;écart
                    </Typography>
                    <Typography variant="h4" className={classes.rejectionMotif}>
                      {file.rejectionReason}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs="auto">
                <Typography className={classes.fileInfoLabel}>
                  Montant
                </Typography>
                <Typography variant="h3">
                  {file.deposit &&
                    formatter.format(file.deposit.adjustedAmount)}
                </Typography>
                <Typography
                  className={
                    file.rejected ? classes.rejected : classes.notRejected
                  }
                  variant="h4"
                >
                  {file.rejected ? "Remise écartée" : "Remise non écartée"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={2}>
            <Grid
              item
              xs={children ? 5 : 7}
              className={classes.sizedPaperGrids}
            >
              <Paper className={classes.photoGrid}>
                <Tooltip title="Cliquez pour agrandir">
                  <Button
                    onClick={handleOpenPhotosModal}
                    disableRipple
                    disableFocusRipple
                    className={classes.photoButton}
                  >
                    {!notFoundSlipImage && (
                      <img
                        className={classes.imgPhoto}
                        alt="Bordereau de remise de chèque"
                        src={slipPhotoURL}
                        onError={handleNotFoundSlipImage}
                      />
                    )}
                    {notFoundSlipImage && (
                      <Typography
                        className={classes.notFoundImage}
                        variant="h2"
                      >
                        IMAGE NON TROUVÉE
                      </Typography>
                    )}
                  </Button>
                </Tooltip>
              </Paper>
            </Grid>
            <Grid
              item
              xs={children ? 4 : 5}
              className={classes.sizedPaperGrids}
            >
              <Paper className={classes.chequesGrid}>
                <Grid container>
                  <Grid item xs={12} className={classes.chequesLabelGrid}>
                    <Typography variant="h4" className={classes.chequesLabel}>
                      Liste des chèques de la remise
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.centeredGrid}>
                    <ChequesTable cheques={cheques} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {children && (
              <Grid item xs={3} className={classes.sizedPaperGrids}>
                {children}
              </Grid>
            )}
            <AdaptiveModal
              openModal={openPhotosModal}
              handleClose={handleCloseChequesModal}
              title="Remise et chèques"
            >
              <ChequesInfos
                cheques={cheques}
                chequesNb={chequesNb}
                slipPhotoURL={slipPhotoURL}
                photoURL={photoURL}
                photosCount={photosCount}
                rectoPhoto={rectoPhoto}
                handleSwitch={handleSwitch}
                handleChangeRectoVerso={handleChangeRectoVerso}
              />
            </AdaptiveModal>
          </Grid>
          {inspectionsForm && (
            <InspectionsTable
              inspections={inspectionsForm.inspections}
              commentsNb={inspectionsForm.commentsNb}
              handleStateChange={inspectionsForm.handleStateChange}
              handleChangeFieldValue={inspectionsForm.handleChangeFieldValue}
              handleInspectionCommentsClick={
                inspectionsForm.handleInspectionCommentsClick
              }
            />
          )}
          {linkedFiles}
        </div>
      </Grid>
      {!isChildren && (
        <Grid item xs={4}>
          {statusForm && (
            <ChangeStatusForm
              fileStatus={statusForm.fileStatus}
              handleChangeStatus={statusForm.handleChangeStatus}
              closureStatus={statusForm.closureStatus}
              handleChangeClosureStatus={statusForm.handleChangeClosureStatus}
            />
          )}
          {additionalInfoForm && (
            <div className={classes.additionalInfoGrid}>
              <AdditionalFileInfo
                additionalInfo={additionalInfoForm.additionalInfo}
                handleChangeAdditionalInfo={
                  additionalInfoForm.handleChangeAdditionalInfo
                }
              />
            </div>
          )}
          {childrenFiles}
        </Grid>
      )}
    </Grid>
  );
};

FileInfo.propTypes = {
  file: PropTypes.shape().isRequired,
  children: PropTypes.node,
  inspectionsForm: PropTypes.shape({
    inspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    commentsNb: PropTypes.arrayOf(PropTypes.any).isRequired,
    handleStateChange: PropTypes.func,
    handleChangeFieldValue: PropTypes.func,
    handleInspectionCommentsClick: PropTypes.func,
  }),
  additionalInfoForm: PropTypes.shape({
    additionalInfo: PropTypes.shape({}),
    handleChangeAdditionalInfo: PropTypes.func,
  }),
  statusForm: PropTypes.shape({
    fileStatus: PropTypes.string.isRequired,
    handleChangeStatus: PropTypes.func.isRequired,
    closureStatus: PropTypes.string.isRequired,
    handleChangeClosureStatus: PropTypes.func.isRequired,
  }),
  linkedFiles: PropTypes.node,
  childrenFiles: PropTypes.node,
};

FileInfo.defaultProps = {
  children: null,
  inspectionsForm: null,
  additionalInfoForm: null,
  statusForm: null,
  linkedFiles: null,
  childrenFiles: null,
};

export default FileInfo;
