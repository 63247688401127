import React, { useState, useEffect } from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "./layouts/Theme";
import Router from "./routes/Router";
import BootstrapService from "./services/BootstrapService";

const App = () => {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      await BootstrapService.before();
      setInitializing(false);
    };
    initialize();
  }, []);

  if (initializing) {
    return null;
  }
  return (
    <Theme>
      <CssBaseline />
      <Router />
    </Theme>
  );
};

export default App;
