import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link as LinkRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Home from "@material-ui/icons/HomeOutlined";
import Dashboard from "@material-ui/icons/DashboardOutlined";
import Ballot from "@material-ui/icons/BallotOutlined";
import Group from "@material-ui/icons/GroupOutlined";
import Typography from "@material-ui/core/Typography";
import logoDocaposte from "../img/logo_docaposte.svg";
import AuthService from "../services/AuthService";
import { OPERATOR, PILOT, ADMIN } from "../utils/keycloak";
import history from "../routes/history";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
  },
  logoButton: {
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    marginRight: theme.spacing(5),
    padding: 0,
    borderRadius: 0,
  },
  menuIcon: {
    padding: theme.spacing(0, 1, 0.5, 0),
  },
  img: {
    height: "45px",
    width: "135px",
  },
  link: {
    marginRight: theme.spacing(5),
  },
  userName: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  userNameButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderRadius: "5px",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
    "&:hover": {
      borderWidth: "2px",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const TopBar = () => {
  const classes = useStyles();

  const [role, setRole] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  useEffect(() => {
    AuthService.getRole().then(res => {
      setRole(res);
    });
    AuthService.getProfile().then(res => {
      setName(res && res.firstName ? res.firstName : "");
      setSurname(res && res.lastName ? res.lastName : "");
    });
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    setAnchorEl(null);
    AuthService.logout();
  };

  const handleClickLogo = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.logo}>
            <Button
              className={classes.logoButton}
              disableRipple
              disableFocusRipple
              disableElevation
              onClick={handleClickLogo}
            >
              <img
                className={classes.img}
                src={logoDocaposte}
                alt="Logo de Docaposte"
              />
            </Button>
          </div>
          <div className={classes.menu}>
            {(role === OPERATOR || role === PILOT || role === ADMIN) && (
              <Link
                to={{ pathname: "/" }}
                component={LinkRouter}
                className={classes.link}
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  className={classes.menuIcon}
                >
                  <Home color="secondary" />
                </IconButton>
                Accueil
              </Link>
            )}
            {(role === PILOT || role === ADMIN) && (
              <Link
                to={{ pathname: "/dashboard" }}
                component={LinkRouter}
                className={classes.link}
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  className={classes.menuIcon}
                >
                  <Dashboard color="secondary" />
                </IconButton>
                Tableau de bord
              </Link>
            )}
            {(role === PILOT || role === ADMIN) && (
              <Link
                className={classes.link}
                href={`${process.env.REACT_APP_KEYCLOAK_URL}admin/OLCF/console/#/realms/OLCF/users`}
                target="_blank"
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  className={classes.menuIcon}
                >
                  <Group color="secondary" />
                </IconButton>
                Gestion des utilisateurs
              </Link>
            )}
            {role === ADMIN && (
              <Link
                to={{ pathname: "/inspections" }}
                component={LinkRouter}
                className={classes.link}
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  className={classes.menuIcon}
                >
                  <Ballot color="secondary" />
                </IconButton>
                Gestion de contrôles
              </Link>
            )}
            <Button
              onClick={handleClickMenu}
              variant="outlined"
              color="primary"
              className={classes.userNameButton}
            >
              <Typography
                variant="h3"
                color="primary"
                className={classes.userName}
              >
                {`${name} ${surname}`}
              </Typography>
              <AccountCircle color="primary" />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleClickLogout}>Se déconnecter</MenuItem>
      </Menu>
    </div>
  );
};

export default TopBar;
