import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TopBar from "../components/TopBar";

const useStyles = makeStyles(theme => ({
  layout: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const Layout = props => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <TopBar />
      <div className={classes.root}>{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
