import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  statusType: {
    fontWeight: "bold",
    textTransform: "none",
    marginRight: theme.spacing(1),
  },
}));

const FilesToggleButton = props => {
  const { status, filesNb } = props;
  const classes = useStyles();

  return (
    <>
      <span className={classes.statusType}>{status.label}</span>
      <span> {filesNb}</span>
    </>
  );
};

FilesToggleButton.propTypes = {
  status: PropTypes.shape().isRequired,
  filesNb: PropTypes.number,
};

FilesToggleButton.defaultProps = {
  filesNb: 0,
};

export default FilesToggleButton;
