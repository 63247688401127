import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import Report from "@material-ui/icons/Report";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import history from "../../routes/history";
import FilesService from "../../services/FilesService";
import { EN_COURS, A_TRAITER, getFileFraudStatus } from "../../utils/status";
import Status from "../Status";
import formatter from "../../utils/amount";
import { getFileDate } from "../../utils/date";
import { ANOTHER_FILE_LOCKED, FILE_ALREADY_LOCKED } from "../../utils/error";
import AdaptiveModal from "../Modal/AdaptiveModal";

const useStyles = makeStyles(theme => ({
  table: {
    display: "block",
    overflow: "auto",
  },
  tableCell: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  largerFontTableCell: {
    fontSize: "1rem",
    lineHeigth: "1.25rem",
  },
  boldTableCell: {
    fontWeight: "bold",
  },
  lightTableCell: {
    color: theme.palette.text.primary,
  },
  mediumTableCell: {
    fontWeight: 500,
  },
  fraudCell: {
    padding: 0,
  },
  fraudIcon: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.error,
    fontSize: "1.875rem",
  },
  tableRow: {
    cursor: "pointer",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rejectionMotifCell: {
    width: "17.5rem",
    minWidth: "12rem",
  },
  errorMessage: {
    margin: theme.spacing(2.5, 0),
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  errorIcon: {
    color: theme.palette.background.secondary,
    fontSize: "5em",
    margin: 0,
  },
  errorIconGrid: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  statusTableCell: {
    width: "9rem",
    minWidth: "9rem",
  },
}));

const FilesTable = props => {
  const { files, sort, pagination } = props;
  const classes = useStyles();

  const headCells = [
    { id: "file.recentFraud", label: "" },
    { id: "file.reference", label: "N° dossier" },
    { id: "deposit.reference", label: "N° remise" },
    { id: "childrenCount", label: "Nb remises" },
    { id: "file.cumulativeAmount", label: "Montant cumulé" },
    { id: "file.previousLinkedFilesNumber", label: "Dossiers liés au compte" },
    { id: "file.status", label: "Statut" },
    { id: "file.provenFraud", label: "Fraude" },
    { id: "file.rejectionReason", label: "Motif de l'écart" },
    { id: "deposit.beneficiaryAccount", label: "Compte bénéficiaire" },
    { id: "deposit.chequeNumber", label: "Nb chèque" },
    { id: "deposit.processingDate", label: "Date de traitement" },
  ];

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState(null);
  const [fileToLock, setFileToLock] = useState(null);

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setErrorModal(null);
    setFileToLock(null);
  };

  const handleOpenLockedFile = () => {
    history.push(
      `/file/${errorModal && errorModal.detail && errorModal.detail.fileId}`,
    );
  };

  const handleUnlockLockedFile = async () => {
    await FilesService.updateFile({
      fileId: errorModal && errorModal.detail && errorModal.detail.fileId,
      data: { openAt: null },
    });
    const date = new Date();
    let data;
    if (fileToLock.status === A_TRAITER) {
      data = { status: EN_COURS, openAt: date };
    } else {
      data = { openAt: date };
    }
    await FilesService.updateFile({
      fileId: fileToLock && fileToLock.id,
      data,
    })
      .then(() => {
        history.push(`/file/${fileToLock && fileToLock.id}`);
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === 409
        ) {
          setOpenErrorModal(true);
          setErrorModal(error.response && error.response.data);
        }
      });
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} sur ${count}`;

  const handleClick = async file => {
    const date = new Date();
    let data;
    if (file.status === A_TRAITER) {
      data = { status: EN_COURS, openAt: date };
    } else {
      data = { openAt: date };
    }
    await FilesService.updateFile({
      fileId: file.id,
      data,
    })
      .then(() => {
        history.push(`/file/${file.id}`);
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === 409
        ) {
          setOpenErrorModal(true);
          setErrorModal(error.response && error.response.data);
          setFileToLock(file);
        }
      });
  };

  const getErrorMessage = () => {
    if (errorModal && errorModal.detail) {
      if (errorModal.detail.errorType === ANOTHER_FILE_LOCKED) {
        return `Ce dossier n'est pas accessible car vous avez déjà verrouillé le dossier ${errorModal.detail.fileRef}.`;
      }
      if (errorModal.detail.errorType === FILE_ALREADY_LOCKED) {
        return "Ce dossier n'est pas accessible car il est déjà verrouillé par quelqu'un d'autre.";
      }
    }
    return "Erreur lors de l'ouverture de dossier";
  };

  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                align="center"
                key={headCell.id}
                className={classes.mediumTableCell}
              >
                {(headCell.id === "childrenCount" ||
                  headCell.id === "file.recentFraud") &&
                  headCell.label}
                {headCell.id !== "childrenCount" &&
                  headCell.id !== "file.recentFraud" && (
                    <TableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={
                        sort.orderBy === headCell.id
                          ? sort.order.toLowerCase()
                          : "asc"
                      }
                      onClick={() => sort.handleSort(headCell.id)}
                    >
                      {headCell.label}
                      {sort.orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {sort.order === "DESC"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {files &&
            Array.isArray(files) &&
            files.map(file => {
              const date =
                file.deposit && getFileDate(file.deposit.processingDate);
              return (
                <TableRow
                  key={file.id}
                  className={classes.tableRow}
                  onClick={() => handleClick(file)}
                >
                  <TableCell
                    className={classNames(classes.tableCell, classes.fraudCell)}
                  >
                    {file.recentFraud && (
                      <Tooltip title="Une fraude a été détectée récemment pour ce compte">
                        <Report className={classes.fraudIcon} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.boldTableCell,
                      classes.largerFontTableCell,
                    )}
                  >
                    {file.reference}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.mediumTableCell,
                    )}
                  >
                    {file.deposit && file.deposit.reference}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.largerFontTableCell,
                    )}
                  >
                    {file.children &&
                      Array.isArray(file.children) &&
                      file.children.length + 1}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames(
                      classes.tableCell,
                      classes.largerFontTableCell,
                    )}
                  >
                    {file.cumulativeAmount &&
                      formatter.format(file.cumulativeAmount)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.largerFontTableCell,
                    )}
                  >
                    {file.previousLinkedFilesNumber}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.boldTableCell,
                      classes.statusTableCell,
                    )}
                  >
                    {file.status && <Status status={file.status} />}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.largerFontTableCell,
                    )}
                  >
                    {getFileFraudStatus(file.provenFraud, false)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.tableCell,
                      classes.rejectionMotifCell,
                      classes.lightTableCell,
                    )}
                  >
                    {file.rejectionReason ? file.rejectionReason : "-"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableCell,
                      classes.mediumTableCell,
                    )}
                    align="center"
                  >
                    {file.deposit && file.deposit.beneficiaryAccount}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableCell,
                      classes.mediumTableCell,
                    )}
                    align="center"
                  >
                    {file.deposit && file.deposit.chequeNumber}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableCell,
                      classes.mediumTableCell,
                    )}
                    align="center"
                  >
                    {date}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={pagination.filesNb}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onChangePage={pagination.handleChangePage}
        onChangeRowsPerPage={pagination.handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page: "
        labelDisplayedRows={labelDisplayedRows}
      />
      <AdaptiveModal
        openModal={openErrorModal}
        handleClose={handleCloseErrorModal}
        title="Attention"
        width={100}
      >
        <div>
          <Typography className={classes.errorMessage}>
            {getErrorMessage()}
          </Typography>
          {errorModal &&
            errorModal.detail &&
            errorModal.detail.errorType === ANOTHER_FILE_LOCKED && (
              <div className={classes.buttonGrid}>
                <Button
                  disableRipple
                  disableFocusRipple
                  onClick={handleOpenLockedFile}
                  color="primary"
                >
                  Ouvrir dossier {errorModal.detail.fileRef}
                </Button>
                <Button
                  disableRipple
                  disableFocusRipple
                  onClick={handleUnlockLockedFile}
                  color="primary"
                >
                  Déverrouiller le dossier {errorModal.detail.fileRef} et ouvrir
                  le dossier {fileToLock && fileToLock.reference}
                </Button>
              </div>
            )}
        </div>
      </AdaptiveModal>
    </div>
  );
};

FilesTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sort: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    handleSort: PropTypes.func.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    filesNb: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilesTable;
