import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Axios from "axios";
import App from "./App";
import "./index.css";
import AuthService from "./services/AuthService";

Axios.interceptors.request.use(
  async config => {
    await AuthService.refreshToken();
    const configuration = config;
    configuration.headers.authorization = `Bearer ${AuthService.getToken()}`;
    configuration.headers.Pragma = "no-cache";
    return configuration;
  },
  error => Promise.reject(error),
);

Axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response && response.status === 401) {
      AuthService.logout();
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(<App />, document.getElementById("root"));
