import history from "../routes/history";
import {
  OPERATOR,
  PILOT,
  ADMIN,
  KEYCLOAK_PARAMS,
  REFRESH_TOKEN_TIME,
} from "../utils/keycloak";
import FilesService from "./FilesService";

let keycloak = null;
let refreshPromise = null;

export default class AuthService {
  static checkAccess = async () => {
    // eslint-disable-next-line no-undef
    keycloak = new Keycloak(KEYCLOAK_PARAMS);
    console.info(keycloak);
    if (!keycloak.authenticated) {
      try {
        await keycloak.init({
          onLoad: "login-required",
          checkLoginIframe: false,
        });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return true;
  };

  static getProfile = async () => {
    await keycloak.loadUserProfile();
    return keycloak.profile;
  };

  static getRole = async () => {
    const roles =
      keycloak.resourceAccess &&
      keycloak.resourceAccess["OLCF-front"] &&
      keycloak.resourceAccess["OLCF-front"].roles;
    if (roles && roles.includes(ADMIN)) {
      return ADMIN;
    }
    if (roles && roles.includes(PILOT)) {
      if (history.location.pathname === "/inspections") {
        history.push("/");
      }
      return PILOT;
    }
    if (
      history.location.pathname === "/inspections" ||
      history.location.pathname === "/dashboard"
    ) {
      history.push("/");
    }
    return OPERATOR;
  };

  static logout = async () => {
    const path = history.location.pathname;
    if (path.includes("/file/")) {
      const fileId = path.split("/file/")[1];
      await FilesService.updateFile({
        fileId,
        data: { openAt: null },
      });
      history.push("/");
    }
    keycloak.logout();
  };

  static getToken = () => {
    return keycloak.token;
  };

  static getUserId = () => {
    return keycloak.subject;
  };

  static refreshToken = () => {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        if (!keycloak || !keycloak.authenticated) {
          reject(new Error("Unable to find authenticated user"));
        } else {
          keycloak
            .updateToken(REFRESH_TOKEN_TIME)
            .success(() => {
              refreshPromise = null;
              resolve();
            })
            .error(() => {
              reject(new Error("Unable to refresh token"));
            });
        }
      });
    }
    return refreshPromise;
  };
}
