import React, { useState, useEffect, useCallback, createRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import FilesService from "../services/FilesService";
import Comments from "../components/Comments";
import AlertDialog from "../components/Dialog/AlertDialog";
import history from "./history";
import { INSPECTION, GLOBAL } from "../utils/inspections";
import {
  A_TRAITER,
  CLOS,
  NON_DEFINI,
  EN_COURS,
  FRAUDE_AVEREE,
  FRAUDE_NON_AVEREE,
} from "../utils/status";
import Actions from "../components/Actions";
import LinkedFiles from "../components/LinkedFiles";
import FileInfo from "../components/FileInfo";
import { getFileDate } from "../utils/date";
import AuthService from "../services/AuthService";
import { FILE_NOT_LOCKED_BY_USER } from "../utils/error";
import ChildrenTable from "../components/Table/ChildrenTable";
import Loader from "../components/Loader";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  fileLabel: {
    margin: theme.spacing(1, 0),
  },
  filePage: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  title: {
    maxWidth: theme.spacing(172),
    margin: "auto",
  },
  buttonGrid: {
    margin: "auto",
    textAlign: "center",
  },
  saveButton: {
    height: "fit-content",
  },
  saveButtonGrid: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "auto",
  },
  typographyButton: {
    cursor: "pointer",
  },
  buttonsGridPaper: {
    display: "flex",
    padding: theme.spacing(2, 3),
    height: "100%",
  },
  exitModal: {
    margin: theme.spacing(1),
  },
  back: {
    color: theme.palette.background.neutral,
    cursor: "pointer",
    fontWeight: "normal",
  },
  arrowBackIcon: {
    width: "12px",
    height: "12px",
    marginRight: theme.spacing(1),
  },
  loader: {
    marginTop: theme.spacing(5),
  },
}));

const File = props => {
  const { match } = props;

  const classes = useStyles();

  const [file, setFile] = useState({});
  const [fileInitialStatus, setFileInitialStatus] = useState("");
  const [fileInitialFraudStatus, setFileInitialFraudStatus] = useState("");
  const [fileStatus, setFileStatus] = useState("");
  const [closureStatus, setClosureStatus] = useState("");

  const [defaultInspections, setDefaultInspections] = useState([]);
  const [inspections, setInspections] = useState([]);

  const [notSavedChanges, setNotSavedChanges] = useState(false);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [newLocation, setNewLocation] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [action, setAction] = useState();

  const [openModalComments, setOpenModalComments] = useState(false);
  const [commentsNb, setCommentsNb] = useState([]);
  const [commentType, setCommentType] = useState(GLOBAL);
  const [inspectionType, setInspectionType] = useState();

  const [openModalActions, setOpenModalActions] = useState(false);
  const { fileId } = (match && match.params) || {};

  const [openConfirmationMessage, setOpenConfirmationMessage] = useState(false);
  const [saved, setSaved] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState({
    note: "",
    advancedAmount: null,
    recoveredAmount: null,
    lastNameFirstName: "",
    chequeNumber: "",
    fraudType: null,
  });

  const [childrenFiles, setChildrenFiles] = useState([]);

  const userId = AuthService.getUserId();

  const getFile = useCallback(
    withRedirection => {
      FilesService.getFile(fileId).then(f => {
        setFile(f);
        if (f.supplementaryDetails) {
          const { supplementaryDetails } = f;
          setAdditionalInfo({
            note: supplementaryDetails.note,
            advancedAmount: supplementaryDetails.advancedAmount,
            recoveredAmount: supplementaryDetails.recoveredAmount,
            lastNameFirstName: supplementaryDetails.lastNameFirstName,
            chequeNumber: supplementaryDetails.chequeNumber,
            fraudType: supplementaryDetails.fraudType,
          });
        }
        setFileStatus(f.status);
        setClosureStatus(
          f.status !== CLOS || f.provenFraud === NON_DEFINI
            ? ""
            : f.provenFraud,
        );
        setChildrenFiles(f.children);
        const filterInspections = f.todoInspections.filter(
          ins => ins.actionType !== "ALL_OK",
        );
        const referencedInspections = filterInspections.map(ins => {
          const ref = createRef();
          return { ...ins, ref };
        });
        setDefaultInspections(referencedInspections);
        setInspections(referencedInspections);
        if (withRedirection === "true" && f.openBy !== userId) {
          history.push("/");
        }
      });
    },
    [fileId, userId],
  );

  useEffect(() => {
    getFile("true");
  }, [getFile]);

  useEffect(() => {
    if (file.id) {
      setFileInitialStatus(file.status);
      setFileInitialFraudStatus(file.provenFraud);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.id]);

  useEffect(() => {
    if (!openModalComments) {
      FilesService.getCommentsNb(fileId).then(cm => {
        setCommentsNb(cm);
      });
    }
  }, [fileId, openModalComments]);

  useEffect(() => {
    setInspectionType(
      inspections &&
        Array.isArray(inspections) &&
        inspections[0] &&
        inspections[0].id,
    );
  }, [inspections]);

  const isChangedAdditionalInfo = useCallback(() => {
    return (
      !!file.supplementaryDetails &&
      (file.supplementaryDetails.note !== additionalInfo.note ||
        file.supplementaryDetails.lastNameFirstName !==
          additionalInfo.lastNameFirstName ||
        file.supplementaryDetails.chequeNumber !==
          additionalInfo.chequeNumber ||
        file.supplementaryDetails.fraudType !== additionalInfo.fraudType ||
        file.supplementaryDetails.advancedAmount !==
          additionalInfo.advancedAmount ||
        file.supplementaryDetails.recoveredAmount !==
          additionalInfo.recoveredAmount)
    );
  }, [file.supplementaryDetails, additionalInfo]);

  const isAddedAdditionalInfo = useCallback(() => {
    return (
      !file.supplementaryDetails &&
      (additionalInfo.note !== "" ||
        additionalInfo.lastNameFirstName !== "" ||
        additionalInfo.chequeNumber !== "" ||
        additionalInfo.fraudType ||
        !!additionalInfo.advancedAmount ||
        additionalInfo.advancedAmount === 0 ||
        !!additionalInfo.recoveredAmount ||
        additionalInfo.recoveredAmount === 0)
    );
  }, [file.supplementaryDetails, additionalInfo]);

  useEffect(() => {
    setNotSavedChanges(
      defaultInspections !== inspections ||
        (fileStatus !== "" && fileStatus !== file.status) ||
        (closureStatus !== "" && closureStatus !== file.provenFraud) ||
        childrenFiles !== file.children || isChangedAdditionalInfo() || isAddedAdditionalInfo(),
    );
  }, [
    childrenFiles,
    closureStatus,
    defaultInspections,
    file.children,
    file.provenFraud,
    file.status,
    fileStatus,
    inspections,
    isAddedAdditionalInfo,
    isChangedAdditionalInfo,
  ]);

  const changeChildProvenFraud = id => {
    const newChildrenFiles = childrenFiles.map(childFile => {
      if (childFile.id !== id) {
        return childFile;
      }
      return {
        ...childFile,
        provenFraud:
          childFile.provenFraud === FRAUDE_AVEREE
            ? FRAUDE_NON_AVEREE
            : FRAUDE_AVEREE,
      };
    });
    setChildrenFiles(newChildrenFiles);
  };

  const changeInspections = async () => {
    let res = true;
    let response = null;
    await Promise.all(
      defaultInspections.map(di => {
        const ins = inspections.find(
          i =>
            di.id === i.id &&
            (di.status !== i.status ||
              (di.displayInputField &&
                di.inputFieldValue !== i.inputFieldValue)),
        );
        if (ins) {
          const data = {};
          if (di.status !== ins.status) {
            data.status = ins.status;
          }
          if (
            di.displayInputField &&
            di.inputFieldValue !== ins.inputFieldValue
          ) {
            data.inputFieldValue = ins.inputFieldValue;
          }
          response = FilesService.updateInspection({
            fileId,
            inspectionId: ins.id,
            data,
          });
        }
        return response;
      }),
    ).catch(error => {
      res = false;
      if (
        error.response &&
        error.response.data &&
        error.response.data.status === 409 &&
        error.response.data.detail
      ) {
        if (error.response.data.detail.errorType === FILE_NOT_LOCKED_BY_USER) {
          setErrorMessage(
            "Ce dossier n'est plus verouillé par vous. Merci de retourner à l'accueil et voir s'il est toujours accessible.",
          );
        } else {
          setErrorMessage(error.response.data.detail);
        }
      }
    });
    return res;
  };

  const changeChildrenFiles = async () => {
    let res = true;
    let response = null;
    await Promise.all(
      Array.isArray(childrenFiles) &&
        childrenFiles.map(cf => {
          const childFile = file?.children?.find(
            f => cf.id === f.id && cf.provenFraud !== f.provenFraud,
          );
          if (childFile) {
            response = FilesService.updateChildFile({
              fileId: file?.id,
              childFileId: cf.id,
              provenFraud: cf.provenFraud,
            });
          }
          return response;
        }),
    ).catch(error => {
      res = false;
      if (
        error.response &&
        error.response.data &&
        error.response.data.status === 409 &&
        error.response.data.detail
      ) {
        if (error.response.data.detail.errorType === FILE_NOT_LOCKED_BY_USER) {
          setErrorMessage(
            "Ce dossier n'est plus verouillé par vous. Merci de retourner à l'accueil et voir s'il est toujours accessible.",
          );
        } else {
          setErrorMessage(
            "Erreur lors de modification des remises liées au dossier",
          );
        }
      }
    });
    return res;
  };

  const handleStateChange = (id, status) => {
    const newInspections = inspections.map(ins => {
      if (ins.id !== id || ins.status === status) {
        return ins;
      }
      return {
        ...ins,
        status,
      };
    });
    setInspections(newInspections);
  };

  const handleChangeFieldValue = id => event => {
    const newInspections = inspections.map(ins => {
      if (ins.id !== id) {
        return ins;
      }
      return {
        ...ins,
        inputFieldValue: event.target.value?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      };
    });
    setInspections(newInspections);
  };

  const handleCloseCommentsModal = () => {
    setOpenModalComments(false);
  };

  const handleCloseActionsModal = () => {
    setOpenModalActions(false);
  };

  const handleClickSave = async () => {
    const responseInspections = await changeInspections();
    let responseStatus = true;
    const data = {};
    if (fileStatus !== file.status && fileStatus !== "") {
      data.status = fileStatus;
    }
    if (closureStatus !== "" && closureStatus !== file.provenFraud) {
      data.provenFraud = closureStatus;
    }
    let responseChildrenFiles = true;
    if (childrenFiles !== file.children) {
      responseChildrenFiles = await changeChildrenFiles();
    }
    if (isAddedAdditionalInfo()) {
      data.supplementaryDetails = {};
      if (additionalInfo.note !== "") {
        data.supplementaryDetails.note = additionalInfo.note;
      }
      if (
        additionalInfo.advancedAmount ||
        additionalInfo.advancedAmount === 0
      ) {
        data.supplementaryDetails.advancedAmount =
          additionalInfo.advancedAmount === ""
            ? null
            : additionalInfo.advancedAmount;
      }
      if (
        additionalInfo.recoveredAmount ||
        additionalInfo.recoveredAmount === 0
      ) {
        data.supplementaryDetails.recoveredAmount =
          additionalInfo.recoveredAmount === ""
            ? null
            : additionalInfo.recoveredAmount;
      }
      if (additionalInfo.lastNameFirstName !== "") {
        data.supplementaryDetails.lastNameFirstName =
          additionalInfo.lastNameFirstName;
      }
      if (additionalInfo.chequeNumber !== "") {
        data.supplementaryDetails.chequeNumber = additionalInfo.chequeNumber;
      }
      if (additionalInfo.fraudType) {
        data.supplementaryDetails.fraudType = additionalInfo.fraudType;
      }
    }
    if (isChangedAdditionalInfo()) {
      data.supplementaryDetails = {};
      const { supplementaryDetails } = file;
      data.supplementaryDetails.id = supplementaryDetails.id;
      if (additionalInfo.note !== supplementaryDetails.note) {
        data.supplementaryDetails.note = additionalInfo.note;
      }
      if (
        additionalInfo.advancedAmount !== supplementaryDetails.advancedAmount
      ) {
        data.supplementaryDetails.advancedAmount =
          additionalInfo.advancedAmount === ""
            ? null
            : additionalInfo.advancedAmount;
      }
      if (
        additionalInfo.recoveredAmount !== supplementaryDetails.recoveredAmount
      ) {
        data.supplementaryDetails.recoveredAmount =
          additionalInfo.recoveredAmount === ""
            ? null
            : additionalInfo.recoveredAmount;
      }
      if (
        additionalInfo.lastNameFirstName !==
        supplementaryDetails.lastNameFirstName
      ) {
        data.supplementaryDetails.lastNameFirstName =
          additionalInfo.lastNameFirstName;
      }
      if (additionalInfo.chequeNumber !== supplementaryDetails.chequeNumber) {
        data.supplementaryDetails.chequeNumber = additionalInfo.chequeNumber;
      }
      if (additionalInfo.fraudType !== supplementaryDetails.fraudType) {
        data.supplementaryDetails.fraudType = additionalInfo.fraudType;
      }
    }
    if (Object.keys(data).length !== 0) {
      await FilesService.updateFile({
        fileId: file.id,
        data,
      }).catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === 409
        ) {
          setErrorMessage(
            error.response.data.detail &&
              error.response.data.detail.errorType === FILE_NOT_LOCKED_BY_USER
              ? "Ce dossier n'est plus verouillé par vous. Merci de retourner à l'accueil et voir s'il est toujours accessible."
              : error.response.data.detail,
          );
        } else {
          setErrorMessage("Erreur lors de l'enregistrement");
        }
        responseStatus = false;
      });
    }
    setSaved(
      !!(responseStatus && responseInspections && responseChildrenFiles),
    );
    setOpenConfirmationMessage(true);
    if (notSavedChanges) {
      getFile("false");
    }
  };

  useEffect(() => {
    if (
      fileInitialStatus &&
      file.status &&
      !notSavedChanges &&
      (file.status !== fileInitialStatus ||
        (file.status === CLOS && fileInitialFraudStatus !== file.provenFraud))
    ) {
      history.push("/");
    }
  }, [
    file.status,
    file.provenFraud,
    fileInitialFraudStatus,
    fileInitialStatus,
    notSavedChanges,
  ]);

  // eslint-disable-next-line consistent-return
  window.onbeforeunload = () => {
    if (notSavedChanges && !isConfirmed) {
      return false;
    }
  };

  const handleClickSeeAll = () => {
    setCommentType(GLOBAL);
    if (notSavedChanges) {
      setOpenExitModal(true);
      setAction("open comments");
    } else {
      setOpenModalComments(true);
    }
  };

  const handleClickAddComment = () => {
    setCommentType(GLOBAL);
    if (notSavedChanges) {
      setOpenExitModal(true);
      setAction("open comments");
    } else {
      setOpenModalComments(true);
    }
  };

  const handlClickSeeActions = () => {
    if (notSavedChanges) {
      setOpenExitModal(true);
      setAction("open actions");
    } else {
      setOpenModalActions(true);
    }
  };

  const revertChanges = () => {
    setChildrenFiles(file.children);
    setInspections(defaultInspections);
    setFileStatus(file.status);
    if (file.provenFraud !== NON_DEFINI) {
      setClosureStatus(file.provenFraud);
    } else {
      setClosureStatus("");
    }
    if (file.supplementaryDetails) {
      setAdditionalInfo(file.supplementaryDetails);
    } else {
      setAdditionalInfo({
        note: "",
        advancedAmount: null,
        recoveredAmount: null,
        lastNameFirstName: "",
        chequeNumber: "",
        fraudType: null,
      });
    }
    setAction();
    setIsConfirmed(false);
  };

  useEffect(() => {
    if (isConfirmed) {
      if (newLocation) {
        history.push(newLocation.pathname);
      } else if (action === "open comments") {
        revertChanges();
        setOpenModalComments(true);
      } else if (action === "open actions") {
        revertChanges();
        setOpenModalActions(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  const handleCloseExitModal = () => {
    setNewLocation();
    setOpenExitModal(false);
  };

  const handleQuit = nextLocation => {
    if (!notSavedChanges || isConfirmed) {
      let data;
      if (file.status === EN_COURS) {
        data = { status: A_TRAITER, openAt: null };
      } else {
        data = {
          openAt: null,
        };
      }
      FilesService.updateFile({
        fileId: file.id,
        data,
      });
    }
    if (notSavedChanges && !isConfirmed) {
      setNewLocation(nextLocation);
      setOpenExitModal(true);
      return false;
    }
    return true;
  };

  const handleQuitWithoutSaving = () => {
    setIsConfirmed(true);
    setOpenExitModal(false);
  };

  const handleChangeCommentType = event => {
    setCommentType(event.target.value);
  };

  const handleChangeInspectionType = event => {
    setInspectionType(event.target.value);
  };

  const handleInspectionCommentsClick = inspectionId => {
    setCommentType(INSPECTION);
    setInspectionType(inspectionId);
    if (notSavedChanges) {
      setOpenExitModal(true);
      setAction("open comments");
    } else {
      setOpenModalComments(true);
    }
  };

  const handleChangeStatus = event => {
    setClosureStatus(NON_DEFINI);
    setFileStatus(event.target.value);
  };

  const handleChangeClosureStatus = event => {
    setFileStatus(CLOS);
    setClosureStatus(event.target.value);
  };

  const handleCloseConfirmationMessage = () => {
    setOpenConfirmationMessage(false);
  };

  const handleChangeAdditionalInfo = infoType => event => {
    let info = event.target.value;
    if (infoType === "fraudType" && info === "") {
      info = null;
    }
    setAdditionalInfo({
      note: infoType === "note" ? info?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : additionalInfo.note,
      advancedAmount:
        infoType === "advancedAmount" ? info : additionalInfo.advancedAmount,
      recoveredAmount:
        infoType === "recoveredAmount" ? info : additionalInfo.recoveredAmount,
      lastNameFirstName:
        infoType === "lastNameFirstName"
          ? info?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          : additionalInfo.lastNameFirstName,
      chequeNumber:
        infoType === "chequeNumber"
          ? info?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          : additionalInfo.chequeNumber,
      fraudType: infoType === "fraudType" ? info : additionalInfo.fraudType,
    });
  };

  const linkedFilesForm = <LinkedFiles file={file} />;

  const childrenFilesForm = (
    <ChildrenTable
      childrenFullList={childrenFiles}
      changeChildProvenFraud={changeChildProvenFraud}
    />
  );

  const handleClickGoBackHome = () => {
    history.push("/");
  };

  return (
    <div className={classes.filePage}>
      {!file.id && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {file.id && (
        <>
          <AlertDialog
            title="Changements non enregistrés"
            contentText="Des modifications ont été apportées et doivent être enregistrées pour
      continuer. Si vous poursuivez, ces modifications seront perdues.
      Souhaitez-vous continuer?"
            openClose={openExitModal}
            handleClose={handleCloseExitModal}
            quitWithoutSaving={handleQuitWithoutSaving}
            closeDialog={handleCloseExitModal}
            agree="Continuer sans enregistrer"
            disagree="Annuler"
          />

          <Prompt message={handleQuit} />
          <div className={classes.title}>
            <Typography
              className={classes.back}
              onClick={handleClickGoBackHome}
            >
              <ArrowBack className={classes.arrowBackIcon} />
              Retour
            </Typography>
            <Grid container>
              <Grid item xs>
                <Typography variant="h1" className={classes.fileLabel}>
                  Dossier {file.reference}
                </Typography>
                <Typography>
                  Date de traitement:{" "}
                  {file.deposit && getFileDate(file.deposit.processingDate)}
                </Typography>
              </Grid>
              <Grid item xs className={classes.saveButtonGrid}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickSave}
                  className={classes.saveButton}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </div>
          {file && (
            <FileInfo
              file={file}
              inspectionsForm={{
                inspections,
                commentsNb,
                handleStateChange,
                handleChangeFieldValue,
                handleInspectionCommentsClick,
              }}
              additionalInfoForm={{
                additionalInfo,
                handleChangeAdditionalInfo,
              }}
              statusForm={{
                fileStatus,
                handleChangeStatus,
                closureStatus,
                handleChangeClosureStatus,
              }}
              linkedFiles={linkedFilesForm}
              childrenFiles={childrenFilesForm}
            >
              <Paper className={classes.buttonsGridPaper}>
                <div className={classes.buttonGrid}>
                  <Typography
                    color="primary"
                    onClick={handlClickSeeActions}
                    className={classes.typographyButton}
                  >
                    Voir toutes les actions
                  </Typography>
                  <Divider />
                  <Typography
                    color="primary"
                    onClick={handleClickSeeAll}
                    className={classes.typographyButton}
                  >
                    Voir tous les commentaires
                  </Typography>
                  <Divider />
                  <Typography
                    color="primary"
                    onClick={handleClickAddComment}
                    className={classes.typographyButton}
                  >
                    Ajouter un commentaire global
                  </Typography>
                </div>
              </Paper>
            </FileInfo>
          )}
          <Actions
            file={file}
            openModalActions={openModalActions}
            handleCloseActionsModal={handleCloseActionsModal}
          />
          <Comments
            file={file}
            inspections={inspections}
            commentType={commentType}
            inspectionType={inspectionType}
            openModalComments={openModalComments}
            handleCloseCommentsModal={handleCloseCommentsModal}
            handleChangeCommentType={handleChangeCommentType}
            handleChangeInspectionType={handleChangeInspectionType}
          />
          <Snackbar
            open={openConfirmationMessage}
            autoHideDuration={saved ? 10000 : null}
            onClose={handleCloseConfirmationMessage}
            ClickAwayListenerProps={{
              onClickAway: null
            }}
          >
            <Alert
              onClose={handleCloseConfirmationMessage}
              severity={saved ? "success" : "error"}
            >
              {saved ? "Modifications enregistrées avec succès" : errorMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

File.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default File;
