import axios from "axios";

export default class FilesService {
  static getToDoActions(fileId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/todo-actions/`,
      )
      .then(response => {
        return response.data;
      });
  }

  static getActions({ fileId, page, actionsByPage }) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/audit-actions/`,
        {
          params: {
            page: page || 1,
            limit: actionsByPage || 5,
            sort: "created:DESC",
          },
        },
      )
      .then(response => {
        return response.data;
      });
  }

  static deleteAction(actionId) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_PATH_URL}/available-actions/${actionId}`,
      )
      .then(response => {
        return response.data;
      });
  }

  static updateToDoAction({ fileId, todoActionId, done }) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/todo-actions/${todoActionId}`,
        { done },
      )
      .then(response => {
        return response.data;
      });
  }

  static addAction(actionLabel) {
    return axios
      .post(`${process.env.REACT_APP_API_PATH_URL}/available-actions`, {
        label: actionLabel,
      })
      .then(response => {
        return response.data;
      });
  }
}
