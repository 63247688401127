import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFile from "@material-ui/icons/AttachFile";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { INSPECTION, GLOBAL } from "../utils/inspections";

const useStyles = makeStyles(theme => ({
  addComment: {
    padding: theme.spacing(5, 3, 3, 3),
  },
  addCommentTitle: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  commentType: {
    padding: theme.spacing(0.75),
  },
  publishButton: {
    height: "max-content",
  },
  inspectionType: {
    width: "29rem",
  },
  commentContent: {
    margin: theme.spacing(3, 0, 0.5, 0),
  },
  commentInputField: {
    width: "100%",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fileName: {
    paddingBottom: theme.spacing(1),
    fontSize: "0.675rem",
    marginRight: theme.spacing(2),
  },
  addFileButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  attachFileLabel: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

const CommentForm = props => {
  const {
    inspections,
    showLoader,
    commentType,
    inspectionType,
    commentContent,
    fileName,
    loaderPercent,
    handleChangeCommentType,
    handleChangeInspectionType,
    handleChangeCommentContent,
    handlePublish,
    handleUploadFile,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.addComment}>
      <Typography variant="h3" className={classes.addCommentTitle}>
        Saisie d&apos;un commentaire
      </Typography>
      <RadioGroup value={commentType} onChange={handleChangeCommentType}>
        <FormControlLabel
          value={GLOBAL}
          control={<Radio color="primary" className={classes.commentType} />}
          label={
            <Typography color={commentType === GLOBAL ? "primary" : "initial"}>
              Global
            </Typography>
          }
        />
        <FormControlLabel
          value={INSPECTION}
          control={<Radio color="primary" className={classes.commentType} />}
          label={
            <Select
              className={classes.inspectionType}
              value={inspectionType}
              onChange={handleChangeInspectionType}
              disabled={commentType === GLOBAL}
            >
              {inspections.map(ins => (
                <MenuItem value={ins.id} key={ins.id}>
                  <Typography
                    color={
                      commentType === INSPECTION && inspectionType === ins.id
                        ? "primary"
                        : "initial"
                    }
                  >
                    {ins.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          }
        />
      </RadioGroup>
      <div className={classes.commentContent}>
        <TextField
          multiline
          rows="10"
          placeholder="Tapez votre commentaire"
          variant="outlined"
          onChange={handleChangeCommentContent}
          value={commentContent}
          className={classes.commentInputField}
        />
      </div>
      <input
        id="input-file-attachment"
        type="file"
        style={{ display: "none" }}
        onChange={handleUploadFile}
      />
      <label htmlFor="input-file-attachment">
        <Button
          component="span"
          color="primary"
          className={classes.addFileButton}
        >
          <AttachFile color="primary" />
          <span className={classes.attachFileLabel}>Joindre un fichier</span>
        </Button>
      </label>
      <Typography className={classes.fileName}>{fileName}</Typography>
      {showLoader && (
        <CircularProgress size={20} value={loaderPercent} variant="static" />
      )}
      <div className={classes.buttonGrid}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePublish}
          disabled={!commentContent}
        >
          Publier
        </Button>
      </div>
    </div>
  );
};

CommentForm.propTypes = {
  inspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  commentType: PropTypes.string,
  showLoader: PropTypes.bool.isRequired,
  inspectionType: PropTypes.string,
  commentContent: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  loaderPercent: PropTypes.number.isRequired,
  handleChangeCommentType: PropTypes.func.isRequired,
  handleChangeInspectionType: PropTypes.func.isRequired,
  handleChangeCommentContent: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
};

CommentForm.defaultProps = {
  commentType: GLOBAL,
  inspectionType: null,
};

export default CommentForm;
