import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: 48,
    display: "flex",
    flexDirection: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Loader = props => {
  const { size } = props;

  const classes = useStyles();

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress size={size} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 32,
};

export default Loader;
