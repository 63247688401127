export const EN_SUSPENS = "En suspens";

export const EN_SUSPENS_FRAUDE_AVEREE = `${EN_SUSPENS} - Fraude avérée`;

export const EN_SUSPENS_DEMANDE_TIERS = `${EN_SUSPENS} - Demande tiers / suspicion de fraude`;

export const CLOS = "Clos";

export const FRAUDE_AVEREE = "Fraude averee";

export const FRAUDE_NON_AVEREE = "Fraude non averee";

export const NON_DEFINI = "Non defini";

export const EN_COURS = "En cours";

export const A_TRAITER = "A traiter";

export const getFileFraudStatus = (fraud, reduced) => {
  if (fraud === NON_DEFINI) {
    return "-";
  }
  if (fraud === FRAUDE_AVEREE) {
    return reduced ? "Oui" : "Avérée";
  }
  return reduced ? "Non" : "Non avérée";
};
