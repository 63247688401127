import axios from "axios";
import { INSPECTION } from "../utils/inspections";

export default class CommentService {
  static createComment({ fileId, content, author, target, inspectionId }) {
    const data = {
      content,
      author,
      target,
    };
    if (target === INSPECTION) {
      data.inspectionId = inspectionId;
    }
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH_URL}/files/${fileId}/comments`,
        data,
      )
      .then(response => {
        return response.data;
      });
  }

  static getAttachment({ commentID, attachmentID }) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH_URL}/comments/${commentID}/attachments/${attachmentID}/content`,
        {
          responseType: "blob",
        },
      )
      .then(response => {
        return response;
      });
  }

  static addAttachment({ commentID, file, uploadProgress }) {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH_URL}/comments/${commentID}/attachments`,
        formData,
        uploadProgress,
      )
      .then(response => {
        return response;
      });
  }
}
