import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import ExpandLess from "@material-ui/icons/ExpandLessRounded";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBox from "@material-ui/icons/CheckBoxOutlined";
import formatter from "../../utils/amount";
import AdaptiveModal from "../Modal/AdaptiveModal";
import FileInfo from "../FileInfo";
import { FRAUDE_AVEREE } from "../../utils/status";

const useStyles = makeStyles(theme => ({
  chequesCell: {
    width: "3rem",
  },
  fileInfo: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  boldTableCell: {
    fontWeight: "bold",
  },
  row: {
    cursor: "pointer",
  },
  children: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2, 2, 3, 2),
  },
  expandIconButtonGrid: {
    display: "flex",
    justifyContent: "center",
  },
  expandIconButton: {
    marginTop: -theme.spacing(3),
    background: theme.palette.background.neutral,
    color: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.neutral,
    },
  },
  childrenTable: {
    marginBottom: theme.spacing(1),
    maxHeight: theme.spacing(63),
    overflow: "auto",
  },
  tableCell: {
    padding: theme.spacing(1.5),
  },
}));

const ChildrenTable = props => {
  const { childrenFullList, changeChildProvenFraud } = props;
  const classes = useStyles();

  const headCells = [
    { id: "deposit.reference", label: "N° Remise" },
    { id: "deposit.adjustedAmount", label: "Montant" },
    { id: "file.rejected", label: "Remise écartée" },
    { id: "deposit.chequeNumber", label: "Nb de chèques" },
    { id: "file.provenFraud", label: "Fraude" },
  ];

  const [fullVersion, setFullVersion] = useState(false);
  const [childrenFiles, setChildrenFiles] = useState(childrenFullList);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileToShow, setFileToShow] = useState({});

  const handleClickFullChildrenList = () => {
    setFullVersion(!fullVersion);
  };

  useEffect(() => {
    if (childrenFullList && Array.isArray(childrenFullList)) {
      setChildrenFiles(
        fullVersion ? childrenFullList : childrenFullList.slice(0, 5),
      );
    }
  }, [childrenFullList, fullVersion]);

  const handleClickFile = file => {
    setFileToShow(file);
    setOpenFileModal(true);
  };

  return (
    <div>
      <Paper className={classes.children}>
        <Typography variant="h2">Remises liées au dossier</Typography>
        <div className={classes.childrenTable}>
          {childrenFiles && Array.isArray(childrenFiles) && (
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      align="center"
                      key={headCell.id}
                      className={
                        headCell.id === "deposit.chequeNumber"
                          ? classNames(classes.chequesCell, classes.tableCell)
                          : classes.tableCell
                      }
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {childrenFiles.map(file => {
                  return (
                    <TableRow className={classes.row} key={file.id}>
                      <TableCell
                        align="center"
                        onClick={() => handleClickFile(file)}
                        className={classNames(
                          classes.boldTableCell,
                          classes.tableCell,
                        )}
                      >
                        {file.deposit && file.deposit.reference}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableCell}
                        onClick={() => handleClickFile(file)}
                      >
                        {file.deposit &&
                          formatter.format(file.deposit.adjustedAmount)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        onClick={() => handleClickFile(file)}
                      >
                        {file.rejected ? "Oui" : "Non"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        onClick={() => handleClickFile(file)}
                      >
                        {file.deposit && file.deposit.chequeNumber}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <Checkbox
                          checked={file.provenFraud === FRAUDE_AVEREE}
                          onChange={() => changeChildProvenFraud(file.id)}
                          name="provenFraud"
                          color="primary"
                          checkedIcon={<CheckBox />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
      {childrenFullList &&
        Array.isArray(childrenFullList) &&
        childrenFullList.length > 5 && (
          <div className={classes.expandIconButtonGrid}>
            <IconButton
              className={classes.expandIconButton}
              onClick={handleClickFullChildrenList}
            >
              {fullVersion ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>
        )}
      <AdaptiveModal
        openModal={openFileModal}
        handleClose={() => setOpenFileModal(false)}
        title={`Remise ${fileToShow.deposit && fileToShow.deposit.reference}`}
        width={115}
        withDivider={false}
      >
        <div className={classes.fileInfo}>
          <Typography>
            Date de traitement:{" "}
            {fileToShow.deposit && fileToShow.deposit.processingDate
              ? new Date(fileToShow.deposit.processingDate).toLocaleDateString()
              : ""}
          </Typography>
          <FileInfo file={fileToShow} />
        </div>
      </AdaptiveModal>
    </div>
  );
};

ChildrenTable.propTypes = {
  childrenFullList: PropTypes.arrayOf(PropTypes.shape),
  changeChildProvenFraud: PropTypes.func,
};

ChildrenTable.defaultProps = {
  childrenFullList: [],
  changeChildProvenFraud: null,
};

export default ChildrenTable;
