import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

export const palette = {
  primary: {
    main: "#0000FF",
  },
  secondary: {
    main: "#00008C",
  },
  border: {
    main: "#E0E3F1",
  },
  background: {
    default: "#F2F2F7",
    paper: "#FFFFFF",
    primary: "#.select2-results .select2-highlighted",
    secondary: "#C11A1A",
    disabled: "#A8AED0",
    in_process: "#2DDFD7",
    ok: "#2E8B57",
    neutral: "#417CFF",
    in_suspense: "#FFCD05",
  },
  text: {
    primary: "#666D92",
    ok: "#2E8B57",
    error: "#EB253C",
  },
};

const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: "Montserrat",
    lineHeight: "1.0625rem",
    body1: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.0625rem",
      color: "#666D92",
    },
    h1: {
      fontFamily: "Montserrat",
      fontSize: "1.5rem",
      fontStyle: "normal",
      lineHeight: "1.8125rem",
      fontWeight: "bold",
      color: "#00008C",
    },
    h2: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: "1.375rem",
      color: "#00008C",
    },
    h3: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: "1.25",
      color: "#00008C",
    },
    h4: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "0.875rem",
      lineHeight: "1.0625",
      color: "#00008C",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 5px 50px rgba(56, 56, 56, 0.05)",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
      colorPrimary: {
        backgroundColor: "#FFFFFF",
      },
    },
    MuiFormControlLabel: {
      root: {
        maxWidth: "100%",
      },
      label: {
        maxWidth: "100%",
      },
    },
    MuiInput: {
      root: {
        maxWidth: "100%",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          backgroundColor: "#F2F2F7",
        },
      },
      input: {
        "&$disabled": {
          cursor: "not-allowed",
        },
      },
    },
    input: {
      root: {
        "&$disabled": {
          backgroundColor: "#F2F2F7",
          cursor: "not-allowed",
        },
      },
    },
    MuiLink: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: 500,
        color: " #333333;",
      },
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
          color: "#00008C",
        },
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: "12.5rem",
        border: "1px solid #666D92",
        color: "#666D92",
        height: "2.25rem",
        textTransform: "none",
        "&$selected": {
          color: "#0000FF",
          backgroundColor: "rgba(0, 0, 255, 0.1)",
          borderColor: "#0000FF",
          "&:hover": {
            color: "#0000FF",
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            borderColor: "#0000FF",
          },
        },
        "&:hover": {
          color: "#0000FF",
          backgroundColor: "rgba(0, 0, 255, 0.1)",
          borderColor: "#0000FF",
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        display: "inline-block",
      },
      grouped: {
        "&:not(:first-child)": {
          borderLeft: "1px solid #666D92",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "12.5rem",
        textTransform: "none",
        padding: "0.875rem 1.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.0625rem",
      },
      text: {
        padding: "0.875rem 1.5rem",
      },
    },
    MuiRadio: {
      root: {
        color: "#666D92",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#666D92",
      },
    },
    MuiDivider: {
      root: {
        border: "1px solid #E0E3F1",
        margin: "1rem 0",
      },
    },
    MuiPaper: {
      root: {
        background: "#FFFFFF",
      },
      rounded: {
        borderRadius: "0.625rem",
      },
      elevation1: {
        boxShadow: "0px 5px 50px rgba(56, 56, 56, 0.05)",
      },
    },
    MuiTableSortLabel: {
      root: {
        "&$active": {
          color: "#666D92",
          fontWeight: "bold",
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: "2px",
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        fontSize: "0.875rem",
        fontFamily: "Montserrat",
        lineHeight: "1.0625",
        fontWeight: "normal",
        fontStyle: "normal",
      },
      body: {
        color: "#00008C",
        border: "none",
      },
      head: {
        fontWeight: 600,
        color: "#666D92",
        border: "none",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        color: "#666D92",
        borderRadius: "5px",
      },
    },
    MuiTablePagination: {
      caption: {
        color: "#666D92",
        fontWeight: 500,
      },
    },
    MuiSelect: {
      select: {
        color: "#666D92",
        fontWeight: 500,
      },
    },
  },
});

const Theme = props => {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
