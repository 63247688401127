import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Error from "@material-ui/icons/Error";

const useStyles = makeStyles({
  root: {
    margin: "5%",
    marginTop: "10%",
  },
});

const P404 = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>
        <Error /> Erreur 404
      </h1>
    </div>
  );
};

export default P404;
