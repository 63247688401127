import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PRO, PART } from "../../utils/inspections";

const useStyles = makeStyles(theme => ({
  checkBox: {
    marginRight: theme.spacing(4.5),
  },
}));

const CategoryToggle = props => {
  const { inspectionId, categories, handleCategoryClick } = props;

  const classes = useStyles();

  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.checkBox}
        value={PRO}
        control={
          <Checkbox
            checked={categories.includes(PRO)}
            onChange={handleCategoryClick(inspectionId)}
            color="secondary"
          />
        }
        label={<Typography variant="h3">PRO</Typography>}
        labelPlacement="end"
      />
      <FormControlLabel
        className={classes.checkBox}
        value={PART}
        control={
          <Checkbox
            checked={categories.includes(PART)}
            onChange={handleCategoryClick(inspectionId)}
            color="secondary"
          />
        }
        label={<Typography variant="h3">PART</Typography>}
        labelPlacement="end"
      />
    </FormGroup>
  );
};

CategoryToggle.propTypes = {
  inspectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
};

export default CategoryToggle;
