import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { GLOBAL } from "../utils/inspections";
import AdaptiveModal from "./Modal/AdaptiveModal";
import FilesService from "../services/FilesService";
import CommentService from "../services/CommentService";
import CommentForm from "./CommentForm";
import CommentsList from "./List/CommentsList";
import formatter from "../utils/amount";
import { getFileDate } from "../utils/date";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  commentsInfo: {
    padding: theme.spacing(2, 0, 0, 3),
  },
  commentsInfoRow: {
    marginBottom: theme.spacing(2),
  },
}));

const Comments = props => {
  const {
    file,
    inspections,
    commentType,
    inspectionType,
    openModalComments,
    handleCloseCommentsModal,
    handleChangeCommentType,
    handleChangeInspectionType,
  } = props;

  const classes = useStyles();

  const commentsByPage = 5;
  const [commentContent, setCommentContent] = useState("");
  const [listState, setListState] = useState({
    currentPage: 0,
    hasMoreComments: true,
    comments: [],
    load: false,
  });

  const [publish, setPublish] = useState();
  const [fileName, setFileName] = useState("");
  const [loaderPercent, setLoaderPercent] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (publish) {
      setListState({
        currentPage: 1,
        hasMoreComments: false,
        comments: [],
        load: true,
      });
    }
  }, [publish]);

  const loadComments = async () => {
    const { currentPage, comments } = listState;
    const res = await FilesService.getComments({
      fileId: file.id,
      page: currentPage,
      commentsByPage,
    });
    const content = [...comments, ...res.content];
    setListState({
      currentPage,
      hasMoreComments:
        res.content.length !== 0 && content.length < res.metadatas.total,
      comments: content,
      load: false,
    });
  };

  useEffect(() => {
    if (listState.load) {
      loadComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState.load]);

  const loadMore = () => {
    const { currentPage, comments } = listState;
    setListState({
      currentPage: currentPage + 1,
      hasMoreComments: false,
      comments,
      load: true,
    });
  };

  const creationDate = getFileDate(file.created);

  const handleChangeCommentContent = event => {
    setCommentContent(event.target.value?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  };

  const [attachment, setAttachment] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const handlePublish = async () => {
    if (commentContent) {
      await CommentService.createComment({
        fileId: file.id,
        content: commentContent,
        author: "author",
        target: commentType,
        inspectionId: inspectionType,
      }).then(async res => {
        if (attachment) {
          const uploadProgress = {
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              if (percentCompleted < 100) {
                setLoaderPercent(percentCompleted);
                setShowLoader(true);
              }
            },
          };

          await CommentService.addAttachment({
            commentID: res.id,
            file: attachment,
            uploadProgress,
          }).catch(error => {
            if (error.response.status === 400) {
              setOpenSnackbar(true);
            }
          });
          setShowLoader(false);
          setFileName("");
        }
        setCommentContent("");
        setPublish(res.id);
      });
    }
  };

  const handleUploadFile = e => {
    const fileToUpload = e.target.files[0];
    setAttachment(fileToUpload);
    e.target.value = null;
    setFileName(fileToUpload.name);
  };

  const handleClose = () => {
    setFileName("");
    setCommentContent("");
    handleCloseCommentsModal();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <AdaptiveModal
      openModal={openModalComments}
      handleClose={handleClose}
      title="Commentaires"
    >
      <Grid container>
        <Grid item xs={7}>
          <CommentsList loadMore={loadMore} listState={listState} />
        </Grid>
        <Grid item xs={5}>
          <div className={classes.commentsInfo}>
            <Grid container className={classes.commentsInfoRow}>
              <Grid item xs>
                <Typography>Numéro de dossier:</Typography>
                <Typography variant="h4">{file.reference}</Typography>
              </Grid>
              <Grid item xs>
                <Typography>Numéro de remise:</Typography>
                <Typography variant="h4">
                  {file.deposit && file.deposit.reference}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.commentsInfoRow}>
              <Grid item xs>
                <Typography>Date de création:</Typography>
                <Typography variant="h4">{creationDate}</Typography>
              </Grid>
              <Grid item xs>
                <Typography>Montant de remise:</Typography>
                <Typography variant="h4">
                  {file.deposit &&
                    formatter.format(file.deposit.adjustedAmount)}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <CommentForm
            inspections={inspections}
            commentType={commentType}
            inspectionType={inspectionType}
            commentContent={commentContent}
            fileName={fileName}
            showLoader={showLoader}
            loaderPercent={loaderPercent}
            handleChangeCommentType={handleChangeCommentType}
            handleChangeInspectionType={handleChangeInspectionType}
            handleChangeCommentContent={handleChangeCommentContent}
            handlePublish={handlePublish}
            handleUploadFile={handleUploadFile}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        ClickAwayListenerProps={{
          onClickAway: null
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          La pièce jointe n&apos;a pas été ajoutée. La taille maximale est 5Mb.
        </Alert>
      </Snackbar>
    </AdaptiveModal>
  );
};

Comments.propTypes = {
  file: PropTypes.shape().isRequired,
  inspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  commentType: PropTypes.string,
  inspectionType: PropTypes.string,
  openModalComments: PropTypes.bool.isRequired,
  handleCloseCommentsModal: PropTypes.func.isRequired,
  handleChangeCommentType: PropTypes.func.isRequired,
  handleChangeInspectionType: PropTypes.func.isRequired,
};

Comments.defaultProps = {
  commentType: GLOBAL,
  inspectionType: null,
};

export default Comments;
