import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  THEFT_LOSS,
  COUNTERFEIT,
  FALSIFICATION,
  HIJACK,
  WITHOUT_PROVISION,
  CLOSED_ACCOUNT,
  CAVALRY,
} from "../utils/fraud";

const useStyles = makeStyles(theme => ({
  paperAdditionalInfo: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2.5, 2.5, 1.75, 2.5),
  },
  additionalInfoLabel: {
    marginBottom: theme.spacing(1.5),
  },
  fieldsGrid: {
    padding: theme.spacing(0, 2),
  },
  noteInput: {
    width: "100%",
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  amountInput: {
    maxWidth: "100%",
    width: "9.5rem",
    fontSize: "0.8125rem",
    height: "2rem",
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    "&:focus": {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
      outline: "none",
    },
    padding: theme.spacing(1.5),
    border: "1px solid #C2C7DF",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  disabledAmountInput: {
    backgroundColor: "#F2F2F7",
    cursor: "not-allowed",
  },
  textInput: {
    maxWidth: "100%",
    width: "14.5rem",
    margin: theme.spacing(0, 0, 0.5, 0),
    height: "2rem",
  },
  input: {
    fontSize: "0.8125rem",
  },
  labelGrid: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  inputLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
  },
  dateInput: {
    maxWidth: "100%",
    width: "10.5rem",
    marginTop: 0,
    height: "2rem",
  },
  secondColumn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  infoField: {
    padding: theme.spacing(0.75, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  subLabel: {
    padding: theme.spacing(1.5, 0),
  },
}));

const AdditionalFileInfo = props => {
  const { additionalInfo, handleChangeAdditionalInfo } = props;

  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.paperAdditionalInfo}>
      <Typography variant="h2" className={classes.additionalInfoLabel}>
        Eléments complémentaires
      </Typography>
      <InputLabel className={classes.inputLabel}>Synthèse </InputLabel>
      <TextField
        multiline
        rows="3"
        value={additionalInfo.note || ""}
        className={classes.noteInput}
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        disabled={!handleChangeAdditionalInfo}
        onChange={
          handleChangeAdditionalInfo && handleChangeAdditionalInfo("note")
        }
      />
      <Grid container className={classes.infoField}>
        <Grid item xs={4} className={classes.labelGrid}>
          <InputLabel className={classes.inputLabel}>Nom - prénom</InputLabel>
        </Grid>
        <Grid item xs={8} className={classes.secondColumn}>
          <TextField
            margin="normal"
            size="small"
            value={additionalInfo.lastNameFirstName || ""}
            className={classes.textInput}
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            disabled={!handleChangeAdditionalInfo}
            onChange={
              handleChangeAdditionalInfo &&
              handleChangeAdditionalInfo("lastNameFirstName")
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.infoField}>
        <Grid item xs={4} className={classes.labelGrid}>
          <InputLabel className={classes.inputLabel}>
            Motif de fraude
          </InputLabel>
        </Grid>
        <Grid item xs={8} className={classes.secondColumn}>
          <Select
            value={additionalInfo.fraudType || ""}
            className={classes.textInput}
            inputProps={{
              className: classes.input,
            }}
            variant="outlined"
            disabled={!handleChangeAdditionalInfo}
            onChange={
              handleChangeAdditionalInfo &&
              handleChangeAdditionalInfo("fraudType")
            }
          >
            <MenuItem value="">
              <em>Non renseigné</em>
            </MenuItem>
            <MenuItem value={THEFT_LOSS}>{THEFT_LOSS}</MenuItem>
            <MenuItem value={COUNTERFEIT}>{COUNTERFEIT}</MenuItem>
            <MenuItem value={FALSIFICATION}>{FALSIFICATION}</MenuItem>
            <MenuItem value={HIJACK}>{HIJACK}</MenuItem>
            <MenuItem value={WITHOUT_PROVISION}>{WITHOUT_PROVISION}</MenuItem>
            <MenuItem value={CLOSED_ACCOUNT}>{CLOSED_ACCOUNT}</MenuItem>
            <MenuItem value={CAVALRY}>{CAVALRY}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Typography variant="h4" className={classes.subLabel}>
        En cas de fraude
      </Typography>
      <Grid container className={classes.infoField}>
        <Grid item xs={7} className={classes.labelGrid}>
          <InputLabel className={classes.inputLabel}>
            Montant fonds sortis par le client
          </InputLabel>
        </Grid>
        <Grid item xs={5} className={classes.secondColumn}>
          <input
            margin="normal"
            type="number"
            step="0.01"
            size="small"
            value={
              additionalInfo.advancedAmount !== 0
                ? additionalInfo.advancedAmount || ""
                : 0
            }
            className={classNames(
              classes.amountInput,
              !handleChangeAdditionalInfo ? classes.disabledAmountInput : null,
            )}
            variant="outlined"
            disabled={!handleChangeAdditionalInfo}
            onChange={
              handleChangeAdditionalInfo &&
              handleChangeAdditionalInfo("advancedAmount")
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.infoField}>
        <Grid item xs={7} className={classes.labelGrid}>
          <InputLabel className={classes.inputLabel}>
            Montant fonds récupérés
          </InputLabel>
        </Grid>
        <Grid item xs={5} className={classes.secondColumn}>
          <input
            margin="normal"
            type="number"
            step="0.01"
            size="small"
            value={
              additionalInfo.recoveredAmount !== 0
                ? additionalInfo.recoveredAmount || ""
                : 0
            }
            className={classNames(
              classes.amountInput,
              !handleChangeAdditionalInfo ? classes.disabledAmountInput : null,
            )}
            variant="outlined"
            disabled={!handleChangeAdditionalInfo}
            onChange={
              handleChangeAdditionalInfo &&
              handleChangeAdditionalInfo("recoveredAmount")
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.infoField}>
        <Grid item xs={4} className={classes.labelGrid}>
          <InputLabel className={classes.inputLabel}>N° chèque</InputLabel>
        </Grid>
        <Grid item xs={8} className={classes.secondColumn}>
          <TextField
            margin="normal"
            size="small"
            value={additionalInfo.chequeNumber || ""}
            className={classes.textInput}
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            disabled={!handleChangeAdditionalInfo}
            onChange={
              handleChangeAdditionalInfo &&
              handleChangeAdditionalInfo("chequeNumber")
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

AdditionalFileInfo.propTypes = {
  additionalInfo: PropTypes.shape({
    note: PropTypes.string,
    advancedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    recoveredAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastNameFirstName: PropTypes.string,
    chequeNumber: PropTypes.string,
    fraudType: PropTypes.string,
  }),
  handleChangeAdditionalInfo: PropTypes.func,
};

AdditionalFileInfo.defaultProps = {
  additionalInfo: {
    note: "",
    advancedAmount: null,
    recoveredAmount: null,
    lastNameFirstName: "",
    chequeNumber: "",
    fraudType: null,
  },
  handleChangeAdditionalInfo: null,
};

export default AdditionalFileInfo;
