import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import BlockingToggle from "../Toggle/BlockingToggle";
import CategoryToggle from "../Toggle/CategoryToggle";
import ActionSelect from "../List/ActionSelect";

const useStyles = makeStyles(theme => ({
  label: {
    width: "22.25rem",
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
  table: {
    display: "block",
    overflow: "auto",
  },
  tableCell: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inspectionRow: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    "&:first-child": {
      borderTop: "none",
    },
  },
  blockingToggleCell: {
    minWidth: "15.5rem",
  },
  allOkAction: {
    background: theme.palette.background.paper,
  },
  arrow: {
    fontSize: "3rem",
    height: "2rem",
    color: theme.palette.text.primary,
  },
  allOkGrid: {
    display: "flex",
    alignItems: "center",
  },
  allOkIcon: {
    marginRight: theme.spacing(1.5),
  },
  allOkTable: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    overflow: "auto",
    padding: theme.spacing(3, 0),
    margin: theme.spacing(2.5, 0),
  },
  actionButtonsGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addControl: {
    marginLeft: theme.spacing(1.5),
    color: theme.palette.text.primary,
  },
  arrowCell: {
    width: "15.75rem",
    paddingRight: theme.spacing(6.5),
  },
  deleteInspectionCell: {
    padding: 0,
  },
  categoryToggleCell: {
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
  },
  allOkCategoryToggleCell: {
    width: "15rem",
  },
  allOklabel: {
    width: "30rem",
  },
}));

const AdministrationInspections = props => {
  const classes = useStyles();

  const {
    inspections,
    handleInspections,
    addedInspections,
    handleAddedInspections,
    allOkInspections,
    handleAllOkInspections,
    handleClickAddInspection,
    handleClickDelete,
    actions,
    handleOpenAddActionModal,
    handleOpenDeleteActionModal,
  } = props;

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              Contrôles à réaliser
            </TableCell>
            <TableCell className={classes.tableHead}>Complément</TableCell>
            <TableCell className={classes.tableHead}>
              Type de contrôle
            </TableCell>
            <TableCell
              className={classNames(
                classes.tableHead,
                classes.categoryToggleCell,
              )}
            >
              Catégorie contrôlée
            </TableCell>
            <TableCell className={classes.tableHead}>
              Actions à réaliser en cas d&apos;échec
            </TableCell>
            <TableCell className={classes.tableHead} />
          </TableRow>
        </TableHead>
        <TableBody>
          {inspections.map(ins => (
            <TableRow className={classes.inspectionRow} key={ins.id}>
              <TableCell className={classes.tableCell}>
                <TextField
                  variant="outlined"
                  value={ins.label}
                  size="small"
                  className={classes.label}
                  onChange={handleInspections.handleChangeLabel(ins.id)}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Tooltip title="Afficher ou non la zone de saisie complémentaire de ce contrôle">
                  <Switch
                    checked={ins.displayInputField}
                    onChange={handleInspections.handleClickDisplayField(ins.id)}
                    color="secondary"
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableCell,
                  classes.blockingToggleCell,
                )}
              >
                <BlockingToggle
                  inspectionId={ins.id}
                  blocking={ins.blocking}
                  handleBlockingClick={handleInspections.handleBlockingClick}
                />
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableCell,
                  classes.categoryToggleCell,
                )}
              >
                <CategoryToggle
                  inspectionId={ins.id}
                  categories={ins.categories}
                  handleCategoryClick={handleInspections.handleCategoryClick}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {actions && (
                  <ActionSelect
                    inspectionId={ins.id}
                    availableAction={ins.availableAction}
                    handleChangeAction={handleInspections.handleChangeAction}
                    actions={actions}
                  />
                )}
              </TableCell>
              <TableCell className={classes.deleteInspectionCell}>
                <IconButton onClick={() => handleClickDelete(ins.id, false)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {addedInspections.map((ins, index) => (
            <TableRow key={ins.significance}>
              <TableCell className={classes.tableCell}>
                <TextField
                  variant="outlined"
                  value={ins.label}
                  size="small"
                  className={classes.label}
                  onChange={handleAddedInspections.handleChangeAddedInspectionLabel(
                    index,
                  )}
                />
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <Tooltip title="Afficher ou non la zone de saisie complémentaire de ce contrôle">
                  <Switch
                    checked={ins.displayInputField}
                    onChange={handleAddedInspections.handleAddedInspectionDisplayField(
                      index,
                    )}
                    color="secondary"
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableCell,
                  classes.blockingToggleCell,
                )}
              >
                <BlockingToggle
                  inspectionId={index}
                  blocking={ins.blocking}
                  handleBlockingClick={
                    handleAddedInspections.handleAddedInspectionBlockingClick
                  }
                />
              </TableCell>
              <TableCell
                align="center"
                className={classNames(
                  classes.tableCell,
                  classes.categoryToggleCell,
                )}
              >
                <CategoryToggle
                  inspectionId={index}
                  categories={ins.categories}
                  handleCategoryClick={
                    handleAddedInspections.handleAddedInspectionCategoryClick
                  }
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {actions && (
                  <ActionSelect
                    inspectionId={index}
                    availableAction={ins.availableAction}
                    handleChangeAction={
                      handleAddedInspections.handleChangeAddedInspectionAction
                    }
                    actions={actions}
                  />
                )}
              </TableCell>
              <TableCell className={classes.deleteInspectionCell}>
                <IconButton onClick={() => handleClickDelete(index, true)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container>
        <Grid item xs>
          <Button onClick={handleClickAddInspection}>
            <Add color="primary" />
            <Typography variant="h3" className={classes.addControl}>
              Ajouter un contrôle
            </Typography>
          </Button>
        </Grid>
        <Grid item xs className={classes.actionButtonsGrid}>
          <Button color="primary" onClick={handleOpenAddActionModal}>
            Ajouter une action
          </Button>
          <Button color="primary" onClick={handleOpenDeleteActionModal}>
            Supprimer une action
          </Button>
        </Grid>
      </Grid>
      <div className={classes.allOkTable}>
        <Table>
          <TableBody>
            {allOkInspections.map((ins, index) => (
              <TableRow
                key={
                  ins.categories && ins.categories.length === 2
                    ? `${ins.categories[0]}-${ins.categories[1]}`
                    : ins.categories[0]
                }
              >
                <TableCell className={classes.allOklabel}>
                  {index === 0 && (
                    <div className={classes.allOkGrid}>
                      <CheckCircle
                        className={classes.allOkIcon}
                        color="secondary"
                      />
                      <Typography color="primary" variant="h3">
                        Si tous les contrôles sont positifs
                      </Typography>
                    </div>
                  )}
                </TableCell>
                <TableCell className={classes.allOkCategoryToggleCell}>
                  <CategoryToggle
                    inspectionId={index}
                    categories={ins.categories}
                    handleCategoryClick={
                      handleAllOkInspections.handleAllOkCategoryClick
                    }
                  />
                </TableCell>
                <TableCell align="center" className={classes.arrowCell}>
                  <TrendingFlat className={classes.arrow} />
                </TableCell>
                <TableCell className={classes.label}>
                  <TextField
                    variant="outlined"
                    value={ins.actionLabel}
                    size="small"
                    className={classNames(classes.label, classes.allOkAction)}
                    onChange={handleAllOkInspections.handleChangeActionLabel(
                      index,
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

AdministrationInspections.propTypes = {
  inspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleInspections: PropTypes.shape().isRequired,
  addedInspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleAddedInspections: PropTypes.shape().isRequired,
  allOkInspections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleAllOkInspections: PropTypes.shape().isRequired,
  handleClickAddInspection: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  handleOpenAddActionModal: PropTypes.func.isRequired,
  handleOpenDeleteActionModal: PropTypes.func.isRequired,
};

AdministrationInspections.defaultProps = {
  actions: null,
};

export default AdministrationInspections;
