import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import Lens from "@material-ui/icons/Lens";
import { EN_SUSPENS, EN_COURS, A_TRAITER, CLOS } from "../utils/status";

const useStyles = makeStyles(theme => ({
  statusIcon: {
    width: "10px",
    height: "10px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.125),
  },
  statusIconInProcess: {
    color: theme.palette.background.in_process,
  },
  statusIconInSuspense: {
    color: theme.palette.background.in_suspense,
  },
}));

const Status = props => {
  const { status } = props;

  const classes = useStyles();

  return (
    <div>
      {status && status.includes(EN_SUSPENS) && (
        <Lens
          className={classNames(
            classes.statusIcon,
            classes.statusIconInSuspense,
          )}
        />
      )}
      {status === A_TRAITER && (
        <Lens color="primary" className={classes.statusIcon} />
      )}
      {status === EN_COURS && (
        <Lens
          className={classNames(
            classes.statusIcon,
            classes.statusIconInProcess,
          )}
        />
      )}
      {status === CLOS && <Lens className={classes.statusIcon} />}
      {status && status.includes(EN_SUSPENS) ? EN_SUSPENS : status}
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
