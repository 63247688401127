import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import ExpandLess from "@material-ui/icons/ExpandLessRounded";
import IconButton from "@material-ui/core/IconButton";
import FilesService from "../services/FilesService";
import Loader from "./Loader";
import LinkedParentsTable from "./Table/LinkedParentsTable";

const useStyles = makeStyles(theme => ({
  paperFiles: {
    margin: theme.spacing(2.5, 0, 4, 0),
    padding: theme.spacing(2, 2, 3, 2),
  },
  typography: {
    marginBottom: theme.spacing(1.5),
  },
  fullListButton: {
    fontWeight: "bold",
    borderRadius: "15px",
    textTransform: "none",
    fontSize: "small",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  fullListButtonDiv: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  listsGrid: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 1, 2, 1),
  },
  table: {
    marginBottom: theme.spacing(1),
  },
  fullList: {
    marginBottom: theme.spacing(1),
    maxHeight: theme.spacing(63),
    overflow: "auto",
  },
  expandIconButtonGrid: {
    display: "flex",
    justifyContent: "center",
  },
  expandIconButton: {
    position: "absolute",
    background: theme.palette.background.neutral,
    color: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.neutral,
    },
  },
}));

const LinkedFiles = props => {
  const { file } = props;

  const classes = useStyles();
  const filesByPage = 5;
  const scrollRef = useRef();

  const [previousLinkedFiles, setPreviousLinkedFiles] = useState([]);
  const [disableFullList, setDisableFullList] = useState(false);

  useEffect(() => {
    if (file.id) {
      FilesService.getLinkedFiles({
        fileId: file.id,
        filter: "PREVIOUS",
        limit: filesByPage + 1,
        page: 1,
      }).then(files => {
        if (files && files.previous && Array.isArray(files.previous)) {
          setPreviousLinkedFiles(files.previous.slice(0, filesByPage));
          setDisableFullList(files.previous.length <= filesByPage);
        }
      });
    }
  }, [file.id]);

  const [listStatePreviousFiles, setListStatePreviousFiles] = useState({
    currentPage: 0,
    hasMoreFiles: true,
    files: [],
    load: false,
  });

  const loadPreviousFiles = async () => {
    const { currentPage, files } = listStatePreviousFiles;
    if (currentPage === 1) {
      setListStatePreviousFiles({
        currentPage,
        hasMoreFiles:
          previousLinkedFiles && previousLinkedFiles.length === filesByPage,
        files: previousLinkedFiles,
        load: false,
      });
    } else {
      const res = await FilesService.getLinkedFiles({
        fileId: file.id,
        filter: "PREVIOUS",
        limit: filesByPage,
        page: currentPage,
      });
      const content = [...files, ...res.previous];
      setListStatePreviousFiles({
        currentPage,
        hasMoreFiles: res.previous && res.previous.length === filesByPage,
        files: content,
        load: false,
      });
    }
  };

  useEffect(() => {
    if (listStatePreviousFiles.load) {
      loadPreviousFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatePreviousFiles.load]);

  const loadMorePreviousFiles = () => {
    const { currentPage, files } = listStatePreviousFiles;
    setListStatePreviousFiles({
      currentPage: currentPage + 1,
      hasMoreFiles: false,
      files,
      load: true,
    });
  };

  const [filesListFullVersion, setFilesListFullVersion] = useState(false);

  const handleClickFullFilesList = () => {
    setFilesListFullVersion(!filesListFullVersion);
  };

  return (
    <Paper className={classes.paperFiles}>
      <Typography variant="h2" className={classes.typography}>
        Historique du compte
      </Typography>
      {!filesListFullVersion && (
        <div className={classes.table}>
          {previousLinkedFiles && Array.isArray(previousLinkedFiles) && (
            <LinkedParentsTable linkedFiles={previousLinkedFiles} />
          )}
        </div>
      )}
      {filesListFullVersion && (
        <div className={classes.fullList} ref={scrollRef}>
          <InfiniteScroll
            loadMore={loadMorePreviousFiles}
            hasMore={listStatePreviousFiles.hasMoreFiles}
            loader={<Loader key={0} />}
            useWindow={false}
            pageStart={0}
            getScrollParent={() => scrollRef && scrollRef.current}
          >
            <LinkedParentsTable linkedFiles={listStatePreviousFiles.files} />
          </InfiniteScroll>
        </div>
      )}
      {!disableFullList && (
        <div className={classes.expandIconButtonGrid}>
          <IconButton
            className={classes.expandIconButton}
            onClick={handleClickFullFilesList}
          >
            {filesListFullVersion ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      )}
    </Paper>
  );
};

LinkedFiles.propTypes = {
  file: PropTypes.shape().isRequired,
};

export default LinkedFiles;
