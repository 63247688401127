export const THEFT_LOSS = "Vol/perte";

export const COUNTERFEIT = "Contrefaçon";

export const FALSIFICATION = "Falsification";

export const HIJACK = "Détournement/rejeu";

export const WITHOUT_PROVISION = "Sans provision";

export const CLOSED_ACCOUNT = "Compte clos";

export const CAVALRY = "Cavalerie";
