import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import GetApp from "@material-ui/icons/GetApp";
import FilterFieldsStats from "../components/Filters/FilterFieldsStats";
import FilesStatsTable from "../components/Table/FilesStatsTable";
import { EN_SUSPENS } from "../utils/status";
import FilesService from "../services/FilesService";
import IcoFilter from "../icons/IcoFilter";
import { STATUSES, CATEGORIES } from "../utils/filters";
import { downloadCsv } from "../utils/DownloadUtil";

const useStyles = makeStyles(theme => ({
  dashboard: {
    maxWidth: theme.spacing(220),
    margin: "auto",
    marginTop: theme.spacing(5),
  },
  paperTable: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
  searchGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  input: {
    height: "2.5rem",
    width: "21rem",
  },
  searchIconButton: {
    color: theme.palette.background.paper,
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  searchInput: {
    backgroundColor: theme.palette.background.default,
  },
  searchOutlinedInput: {
    backgroundColor: theme.palette.primary.main,
  },
  searchIcon: {
    fontSize: "1.2rem",
  },
  iconFilterButton: {
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    height: "2.5rem",
  },
  iconFilter: {
    fill: theme.palette.primary.main,
  },
  exportButtonText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  clearIconButton: {
    borderRadius: "0%",
    backgroundColor: theme.palette.background.default,
    marginLeft: -theme.spacing(1),
    "&:hover": {
      borderRadius: "0%",
      backgroundColor: theme.palette.background.default,
      marginLeft: -theme.spacing(1),
    },
  },
  clearIcon: {
    fontSize: "1.2rem",
  },
}));

const Dashboard = props => {
  const classes = useStyles();

  const { userCategories } = props;

  const ENTER_KEY = 13;

  const accessToBothCategories =
    userCategories &&
    Array.isArray(userCategories) &&
    userCategories.length === 2;

  const singleCategoryValue =
    accessToBothCategories === false
      ? userCategories && Array.isArray(userCategories) && userCategories[0]
      : false;

  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({
    status: STATUSES[0].value,
    category: accessToBothCategories
      ? CATEGORIES[0].value
      : singleCategoryValue,
    documentNb: "",
    account: "",
    processingDateStart: "",
    processingDateEnd: "",
    amountMin: "",
    amountMax: "",
  });
  const [fileNb, setFileNb] = useState("");
  const [currentFilters, setCurrentFilters] = useState(
    accessToBothCategories ? null : `file.category||eq||${singleCategoryValue}`,
  );
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("file.status");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState([]);
  const [headers, setHeaders] = useState({});
  const [filesNb, setFilesNb] = useState(0);

  const handleSort = id => {
    setOrder(id === orderBy && order === "ASC" ? "DESC" : "ASC");
    setOrderBy(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeInput = filterType => event => {
    const filter = event.target.value;
    setFilters({
      status: filterType === "status" ? filter : filters.status,
      category: filterType === "category" ? filter : filters.category,
      documentNb:
        filterType === "documentNb"
          ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          : filters.documentNb,
      account:
        filterType === "account" ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : filters.account,
      processingDateStart:
        filterType === "processingDateStart"
          ? filter
          : filters.processingDateStart,
      processingDateEnd:
        filterType === "processingDateEnd" ? filter : filters.processingDateEnd,
      amountMin:
        filterType === "amountMin" ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : filters.amountMin,
      amountMax:
        filterType === "amountMax" ? filter?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : filters.amountMax,
    });
  };

  const getCurrentFilters = () => {
    const allFilters = [];
    if (filters.status) {
      allFilters.push(
        filters.status === EN_SUSPENS
          ? "file.status||sw||En suspens"
          : `file.status||eq||${filters.status}`,
      );
    }
    if (filters.category) {
      allFilters.push(`file.category||eq||${filters.category}`);
    }
    if (filters.documentNb) {
      allFilters.push(`deposit.reference||eq||${filters.documentNb}`);
    }
    if (filters.account) {
      allFilters.push(`deposit.beneficiaryAccount||eq||${filters.account}`);
    }
    if (filters.processingDateStart) {
      allFilters.push(
        `deposit.processingDate||gt||${filters.processingDateStart}`,
      );
    }
    if (filters.processingDateEnd) {
      const currentProcessingDateEnd = new Date(filters.processingDateEnd);
      const newProcessingDateEnd = new Date(
        currentProcessingDateEnd.setDate(
          currentProcessingDateEnd.getDate() + 1,
        ),
      );
      const day = newProcessingDateEnd.getDate();
      const month = newProcessingDateEnd.getMonth() + 1;
      const year = newProcessingDateEnd.getFullYear();
      allFilters.push(
        `deposit.processingDate||lt||${`${year}-${
        month < 10 ? "0" : ""
        }${month}-${day < 10 ? "0" : ""}${day}`}`,
      );
    }
    if (filters.amountMin) {
      allFilters.push(`deposit.adjustedAmount||gt||${filters.amountMin}`);
    }
    if (filters.amountMax) {
      allFilters.push(`deposit.adjustedAmount||lt||${filters.amountMax}`);
    }
    return allFilters;
  };

  const searchFiles = () => {
    setPage(0);
    const allFilters = getCurrentFilters();
    setCurrentFilters(allFilters);
  };

  const resetFilters = () => {
    setFilters({
      status: STATUSES[0].value,
      category: accessToBothCategories
        ? CATEGORIES[0].value
        : singleCategoryValue,
      documentNb: "",
      account: "",
      processingDateStart: "",
      processingDateEnd: "",
      amountMin: "",
      amountMax: "",
    });
  };

  const searchAllFiles = () => {
    if (accessToBothCategories) {
      setCurrentFilters(null);
    } else {
      setCurrentFilters(`file.category||eq||${singleCategoryValue}`);
    }
  };

  const handleClickOpenFilters = () => {
    if (fileNb !== "") {
      setFileNb("");
      searchAllFiles();
    }
    setOpenFilters(!openFilters);
  };

  const eraseFilters = () => {
    setPage(0);
    resetFilters();
    searchAllFiles();
  };

  useEffect(() => {
    FilesService.getStatsFiles(
      currentFilters
        ? {
          filter: currentFilters,
          sort: `${orderBy}:${order}`,
          page: page + 1,
          limit: rowsPerPage,
        }
        : {
          sort: `${orderBy}:${order}`,
          page: page + 1,
          limit: rowsPerPage,
        },
    ).then(fs => {
      setFiles(fs.data && fs.data.content);
      setHeaders(
        fs.data && fs.data.metadatas && fs.data.metadatas.columnHeaders,
      );
      setFilesNb(fs.data && fs.data.metadatas && fs.data.metadatas.total);
    });
  }, [currentFilters, order, orderBy, page, rowsPerPage]);

  const handleExportCSV = () => {
    downloadCsv(
      currentFilters
        ? {
          filter: currentFilters,
          sort: `${orderBy}:${order}`,
          csv: true,
        }
        : {
          sort: `${orderBy}:${order}`,
          csv: true,
        },
    );
  };

  const handleChangeFileNb = event => {
    setFileNb(event.target.value?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  };

  const handleClearSearch = () => {
    setFileNb("");
  };

  const handleSearchByReference = () => {
    if (fileNb) {
      setCurrentFilters(
        accessToBothCategories
          ? `file.reference||eq||${fileNb}`
          : [
            `file.category||eq||${singleCategoryValue}`,
            `file.reference||eq||${fileNb}`,
          ],
      );
    } else {
      setPage(0);
      searchAllFiles();
    }
  };

  const handleReferenceSearchKeyUp = event => {
    if (event.keyCode === ENTER_KEY) {
      handleSearchByReference();
    }
  };

  return (
    <div className={classes.dashboard}>
      <Grid container>
        <Grid item xs className={classes.buttonGrid}>
          <Button color="primary" onClick={handleExportCSV}>
            <GetApp />
            <span className={classes.exportButtonText}>Exporter en CSV</span>
          </Button>
        </Grid>
        <Grid item xs className={classes.searchGrid}>
          <TextField
            type="text"
            disabled={openFilters}
            size="small"
            label="Recherche N° dossier"
            value={fileNb}
            className={classes.input}
            onChange={handleChangeFileNb}
            onKeyUp={handleReferenceSearchKeyUp}
            variant="outlined"
            InputProps={{
              inputProps: {
                className: classes.searchInput,
              },
              className: openFilters ? null : classes.searchOutlinedInput,
              endAdornment: (
                <InputAdornment position="end">
                  {fileNb && (
                    <IconButton
                      className={classes.clearIconButton}
                      onClick={handleClearSearch}
                    >
                      <Clear className={classes.clearIcon} />
                    </IconButton>
                  )}
                  <IconButton
                    className={classes.searchIconButton}
                    edge="end"
                    disabled={openFilters}
                    onClick={handleSearchByReference}
                  >
                    <Search className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ToggleButton
            className={classes.iconFilterButton}
            selected={openFilters}
            onClick={handleClickOpenFilters}
            value="open"
          >
            <IcoFilter className={classes.iconFilter} />
          </ToggleButton>
        </Grid>
      </Grid>
      {userCategories && openFilters && (
        <FilterFieldsStats
          filters={filters}
          accessToBothCategories={accessToBothCategories}
          handleChangeInput={handleChangeInput}
          eraseFilters={eraseFilters}
          searchFiles={searchFiles}
        />
      )}
      <Paper className={classes.paperTable}>
        <FilesStatsTable
          files={files}
          headers={headers}
          sort={{
            order,
            orderBy,
            handleSort,
          }}
          pagination={{
            filesNb,
            page,
            rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
          }}
        />
      </Paper>
    </div>
  );
};

Dashboard.propTypes = {
  userCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Dashboard;
