export const OK = "OK";

export const KO = "KO";

export const DEFAULT = "TODO";

export const INSPECTION = "INSPECTION";

export const GLOBAL = "GLOBAL";

export const PRO = "PRO";

export const PART = "PART";

export const ALL_OK = "ALL_OK";

export const INSPECTION_KO = "INSPECTION_KO";
