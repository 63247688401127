export const PUBLIC_ROUTING = [
  {
    path: "/home",
    key: "home-not-connected",
  },
];

export const PRIVATE_ROUTING = [
  {
    path: "/",
    key: "home",
  },
  {
    path: "/dashboard",
    key: "dashboard",
  },
  {
    path: "/inspections",
    key: "inspections",
  },
  {
    path: "/file/:fileId",
    key: "file",
  },
];

export default {
  PUBLIC_ROUTING,
  PRIVATE_ROUTING,
};
