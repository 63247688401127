import CommentService from "../services/CommentService";
import FilesService from "../services/FilesService";

const successCallback = (data, fileName, type) => {
  const file = new Blob([data], {
    type: type || "application/octet-stream",
  });
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    const fileURL = URL.createObjectURL(file);
    const element = document.createElement("a");
    element.setAttribute("href", fileURL);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

export const download = ({ commentID, attachmentID }) => {
  CommentService.getAttachment({ commentID, attachmentID }).then(res => {
    if (res) {
      let name =
        res.headers &&
        res.headers["content-disposition"] &&
        res.headers["content-disposition"].match(
          /(filename=|filename\*="")(.*)/,
        )[2];
      const type = res.headers && res.headers["content-type"];
      if (name) {
        name = name.replace(/^"(.*)"$/, "$1");
        successCallback(res.data, decodeURI(name), type);
      }
    }
  });
};

export const downloadCsv = ({ filter, sort, csv }) => {
  FilesService.getStatsFiles({ filter, sort, csv }).then(res => {
    if (res) {
      const type = res.headers && res.headers["content-type"];
      successCallback(res.data, decodeURI("olcf-statistiques.csv"), type);
    }
  });
};
