import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1, 3, 0, 0),
    height: "2.5rem",
  },
  doubleInput: {
    width: "13rem",
  },
  singleInput: {
    width: "15.5rem",
  },
  inputLabel: {
    fontWeight: 500,
    color: "#00008C",
  },
  error: {
    color: theme.palette.background.secondary,
    fontSize: "0.75rem",
  },
}));

const FilterField = props => {
  const { label, type, input, secondInput } = props;

  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <TextField
        margin="normal"
        type={type}
        size="small"
        value={input.value}
        placeholder={input.placeholder}
        error={!!input.error}
        className={classNames(
          classes.input,
          !secondInput ? classes.singleInput : classes.doubleInput,
        )}
        variant="outlined"
        onChange={input.handleChangeInput}
      />
      {!!secondInput && (
        <TextField
          margin="normal"
          type={type}
          size="small"
          value={secondInput.secondValue}
          placeholder={secondInput.placeholderSecondInput}
          error={!!secondInput.secondInputError}
          className={classNames(classes.input, classes.doubleInput)}
          variant="outlined"
          onChange={secondInput.handleChangeSecondInput}
        />
      )}
      {(!!input.error || (secondInput && !!secondInput.secondInputError)) && (
        <div>
          <Typography className={classes.error} variant="inherit">
            {input.error || (secondInput && secondInput.secondInputError)}
          </Typography>
        </div>
      )}
    </>
  );
};

FilterField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleChangeInput: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  secondInput: PropTypes.shape({
    secondValue: PropTypes.string,
    placeholderSecondInput: PropTypes.string,
    handleChangeSecondInput: PropTypes.func,
    secondInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
};

FilterField.defaultProps = {
  secondInput: null,
  input: {
    placeholder: "",
    error: false,
  },
};

export default FilterField;
